<template>
  <v-container
    class="production-detail"
    :data-test-id="'production_' + production.id + '_detail'"
  >
    <v-row>
      <v-col v-if="hasProductionAttributes" class="production-attributes">
        <v-card flat outlined>
          <v-list>
            <v-list-item v-if="production.mediaType">
              <v-list-item-icon><v-icon>mdi-image</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :data-test-id="'production_' + production.id + '_media_type'"
                >
                  {{ production.mediaType }}
                </v-list-item-title>
                <v-list-item-subtitle>Media Type</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="production.pickupCode">
              <v-list-item-icon><v-icon>mdi-qrcode</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title
                  :data-test-id="'production_' + production.id + '_pickupCode'"
                >
                  {{ production.pickupCode }}
                </v-list-item-title>
                <v-list-item-subtitle>Pickup Code</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="production.pickupCodeType">
              <v-list-item-icon
                ><v-icon>mdi-qrcode-scan</v-icon></v-list-item-icon
              >
              <v-list-item-content>
                <v-list-item-title
                  :data-test-id="
                    'production_' + production.id + '_pickupCodeType'
                  "
                >
                  {{ production.pickupCodeType }}
                </v-list-item-title>
                <v-list-item-subtitle>Pickup Code Type</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
      <v-col>
        <v-card flat outlined class="production-wallet">
          <v-card-title>Wallet</v-card-title>
          <v-list class="pa-3">
            <v-list-item-group v-if="wallet">
              <v-list-item
                data-test-id="orderProductionWallet"
                :href="wallet.url"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-wallet</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ wallet.filename }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <div
              v-else
              data-test-id="orderProductionNoWalletsMsg"
              class="d-flex flex-grow-1 justify-center"
            >
              <v-subheader> This production has no wallet </v-subheader>
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card flat outlined>
          <v-card-title>Production Items</v-card-title>
          <v-data-table
            show-expand
            :headers="itemHeaders"
            :items="getProductionItems(production)"
            :items-per-page="-1"
            item-key="orderItemId"
            hide-default-footer
          >
            <!-- eslint-disable-next-line -->
            <template #item.data-table-expand="{ item, isExpanded, expand }">
              <v-btn
                v-if="isExpanded"
                icon
                :disabled="disabled"
                :data-test-id="'productionItem_' + item.id + '_hideBtn'"
                @click="expand(false)"
              >
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
              <v-btn
                v-else
                icon
                :disabled="disabled"
                :data-test-id="'productionItem_' + item.id + '_expandBtn'"
                @click="expand(true)"
              >
                <v-icon>mdi-chevron-right</v-icon>
              </v-btn>
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.status="{ item }">
              <StatusChip :status="item.status" />
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.rowTotalInclTax="{ item }">
              {{
                $parseFractionUnitToString(item.rowTotalInclTax, order.currency)
              }}
            </template>

            <!-- eslint-disable-next-line -->
            <template #item.priceInclTax="{ item }">
              {{
                $parseFractionUnitToString(item.priceInclTax, order.currency)
              }}
            </template>

            <template #expanded-item="{ item }">
              <td :colspan="itemHeaders.length + 1">
                <div class="production-item-detail">
                  <GenericAttributesCard
                    title="Attributes"
                    icon="mdi-format-list-bulleted-square"
                    :attributes="item.attributes"
                    :data-test-id="'productionItem_' + item.id + '_attributes'"
                  />

                  <GenericAttributesCard
                    title="Cancellation Attributes"
                    icon="mdi-format-list-bulleted-square"
                    :attributes="item.cancellationAttributes"
                    :data-test-id="
                      'productionItem_' + item.id + '_cancellationAttributes'
                    "
                  />
                </div>
              </td>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import StatusChip from "../../common/display-helpers/StatusChip";
import GenericAttributesCard from "../../common/display-helpers/GenericAttributesCard";
export default {
  components: {
    StatusChip,
    GenericAttributesCard,
  },

  props: {
    production: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  methods: {
    getProductionItems(production) {
      const orderItems = this.order.items;
      if (!orderItems) return [];
      const items = orderItems
        .filter(({ id }) => production?.orderItemIds?.includes(id))
        .map((item) => {
          const attributes = production?.attributes?.find(
            (attr) => attr.orderItemId === item.id
          );
          const cancellationAttributes =
            production?.cancellationAttributes?.find(
              (attr) => attr.orderItemId === item.id
            );
          return {
            ...item,
            attributes,
            cancellationAttributes,
          };
        });
      return items;
    },
  },

  computed: {
    hasProductionAttributes() {
      return (
        this.production.mediaType ||
        this.production.pickupCode ||
        this.production.pickupCodeType
      );
    },

    itemHeaders() {
      return [
        { text: "Item Nr.", value: "number" },
        { text: "Product", value: "sku" },
        { text: "Status", value: "status" },
        { text: "Price", value: "priceInclTax" },
        { text: "Quantity", value: "qty" },
        { text: "Total", value: "rowTotalInclTax" },
      ];
    },

    wallet() {
      const attachments = this.order?.attachments ?? [];
      return attachments.find(({ type, productionId }) => {
        return type === "Wallet" && productionId === this.production.id;
      });
    },
  },
};
</script>

<style scoped>
.production-detail .production-attributes,
.production-detail .production-wallet {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.production-item-detail {
  display: flex;
  flex-direction: column;
  flex: 1 1 100%;
  padding: 12px;
}

.production-item-detail > .generic-attributes-card + .generic-attributes-card {
  margin-top: 8px;
}
</style>