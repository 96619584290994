<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card
          outlined
          class="order-item-product-card"
          data-test-id="orderItemProductCard"
        >
          <v-card-title>
            <v-icon left>mdi-cart</v-icon>
            Product
          </v-card-title>
          <v-container>
            <v-list two-line>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    <a
                      :href="
                        $router.resolve({
                          name: 'productDetail',
                          params: { product: item.sku },
                        }).href
                      "
                      target="_blank"
                    >
                      {{ item.sku }}
                    </a>
                  </v-list-item-title>
                  <v-list-item-subtitle>SKU</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> {{ item.id }} </v-list-item-title>
                  <v-list-item-subtitle>Item-ID</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="item.groupId">
                <v-list-item-content>
                  <v-list-item-title> {{ item.groupId }} </v-list-item-title>
                  <v-list-item-subtitle>Group-ID</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.productType }}
                  </v-list-item-title>
                  <v-list-item-subtitle>Product-Type</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title> {{ item.taxClass }} </v-list-item-title>
                  <v-list-item-subtitle>Tax Class</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-container>
        </v-card>
      </v-col>
      <v-col>
        <v-card
          outlined
          class="order-item-price-card"
          data-test-id="orderItemPriceCard"
        >
          <v-card-title>
            <v-icon left>mdi-currency-usd</v-icon>
            Price
          </v-card-title>
          <v-container class="order-price-container">
            <v-row>
              <v-col> Subtotal: </v-col>
              <v-col>
                <div class="order-price-amount">
                  {{
                    $parseFractionUnitToString(
                      item.priceExclTax,
                      item.currency || order.currency
                    )
                  }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col> Tax ({{ item.taxClass }}): </v-col>
              <v-col>
                <div class="order-price-amount">
                  {{
                    "+ " +
                    $parseFractionUnitToString(
                      item.taxAmount,
                      item.currency || order.currency
                    )
                  }}
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col> Total Discount: </v-col>
              <v-col>
                <div class="order-price-amount">
                  {{
                    $parseFractionUnitToString(
                      item.discountAmount,
                      item.currency || order.currency
                    )
                  }}
                </div>
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col> Total: </v-col>
              <v-col>
                <div class="order-price-amount">
                  {{
                    $parseFractionUnitToString(
                      item.priceInclTax,
                      item.currency || order.currency
                    )
                  }}
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="hasAttributes">
        <GenericAttributesCard
          :key="item.id"
          title="Attributes"
          icon="mdi-format-list-bulleted-square"
          :attributes="item.attributes"
          data-test-id="orderItemAttributesCard"
        />
      </v-col>
      <v-col>
        <v-card flat outlined class="order-item-wallets-overview">
          <v-card-title>Wallets</v-card-title>
          <v-list class="pa-3">
            <v-list-item-group v-if="wallets.length > 0">
              <v-list-item
                v-for="(wallet, index) in wallets"
                :key="index"
                :href="wallet.url"
                :data-test-id="'orderItemWallet_' + wallet.filename"
              >
                <v-list-item-avatar>
                  <v-icon>mdi-wallet</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{ wallet.filename }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
            <div
              v-else
              data-test-id="orderItemNoWalletsMsg"
              class="d-flex flex-grow-1 justify-center"
            >
              <v-subheader> This item has no wallets </v-subheader>
            </div>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col v-if="hasPersonAttributes">
        <GenericAttributesCard
          :key="item.id"
          title="Person Attributes"
          icon="mdi-account"
          :attributes="item.personAttributes"
          data-test-id="orderItemPersonAttrsCard"
        />
      </v-col>
      <v-col v-if="hasProductAttributes">
        <GenericAttributesCard
          :key="item.id"
          title="Product Attributes"
          icon="mdi-cart-plus"
          :attributes="item.productAttributes"
          data-test-id="orderItemProductAttrsCard"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import GenericAttributesCard from "../../common/display-helpers/GenericAttributesCard";
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  components: {
    GenericAttributesCard,
  },

  computed: {
    hasAttributes() {
      return (
        this.item.attributes && Object.keys(this.item.attributes).length > 0
      );
    },

    hasProductAttributes() {
      return (
        this.item.productAttributes &&
        Object.keys(this.item.productAttributes).length > 0
      );
    },

    hasPersonAttributes() {
      return (
        this.item.personAttributes &&
        Object.keys(this.item.personAttributes).length > 0
      );
    },

    wallets() {
      const attachments = this.order?.attachments ?? [];
      return attachments.filter(({ type, orderItemIds }) => {
        return type === "Wallet" && orderItemIds?.includes?.(this.item.id);
      });
    },
  },
};
</script>

<style scoped>
.order-item-price-card,
.order-item-product-card,
.item-attributes-card {
  height: 100%;
}

.order-price-container .col {
  display: flex;
}

.order-price-amount {
  display: flex;
  align-items: center;
}

.order-price-container .col {
  display: flex;
}

.order-price-container .v-divider {
  margin: 12px 0 12px 0;
}

.order-item-wallets-overview {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>