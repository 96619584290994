<template>
  <v-container>
    <v-data-table
      :items="statusHistory"
      :headers="headers"
      :items-per-page="-1"
      :expanded="expanded"
      item-key="timestamp"
      sort-by="timestamp"
      hide-default-footer
      show-expand
      no-data-text="No status history found"
      data-test-id="paymentStatusHistoryList"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          v-if="isExpanded && (item.message || item.pspResponse)"
          icon
          data-test-id="payment_status_history_hide_btn"
          @click="expand(false)"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          v-else-if="item.message || item.pspResponse"
          icon
          data-test-id="payment_status_history_expand_btn"
          @click="expand(true)"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.timestamp="{ item }">
        {{ $getLocalizedDate(item.timestamp) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.code="{ item }">
        <StatusChip :status="item.code" />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.message="{ item }">
        <div
          v-if="item.message"
          class="status-history-message"
          :title="item.message"
        >
          {{ item.message }}
        </div>
        <v-subheader v-else>No message</v-subheader>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <v-container>
            <v-card flat outlined>
              <v-card-title>Message</v-card-title>
              <v-card-text
                v-if="item.message"
                data-test-id="payment_status_history_message"
              >
                {{ item.message }}
              </v-card-text>
            </v-card>
          </v-container>
          <v-container v-if="item.pspResponse">
            <GenericAttributesCard
              :key="item.id"
              title="PSP Response"
              icon="mdi-format-list-bulleted-square"
              :attributes="item.pspResponse"
              data-test-id="payment_status_history_psp_response"
            />
          </v-container>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import StatusChip from "../../common/display-helpers/StatusChip";
import GenericAttributesCard from "../../common/display-helpers/GenericAttributesCard";

export default {
  props: {
    statusHistory: {
      type: Array,
      required: true,
    },
  },

  components: {
    StatusChip,
    GenericAttributesCard,
  },

  data() {
    return {
      expanded: [],
    };
  },

  computed: {
    headers() {
      return [
        { text: "Timestamp", value: "timestamp" },
        { text: "Status", value: "code" },
        { text: "Message", value: "message" },
      ];
    },
  },
};
</script>

<style scoped>
.status-history-message {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>