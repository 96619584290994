<template>
  <v-container class="order-items-container">
    <v-toolbar flat v-if="isCancelable">
      <v-spacer></v-spacer>
      <v-btn
        v-if="isCancelable && this.cancelOrder === false"
        outlined
        color="red"
        data-test-id="orderCancelBtn"
        :loading="validateCancellation"
        :disabled="validateCancellation"
        @click="enableCancellation()"
      >
        Cancel
      </v-btn>
      <v-btn
        v-if="cancelOrder"
        outlined
        data-test-id="abortOrderCancelBtn"
        @click="
          selectedItems = [];
          cancelOrder = false;
        "
      >
        Abort
      </v-btn>
    </v-toolbar>
    <v-form ref="cancellationForm">
      <v-container class="order-items-table">
        <v-data-table
          v-model="selectedItems"
          :show-select="cancelOrder"
          :headers="headers"
          :items="order.items"
          :expanded="expanded"
          :items-per-page="-1"
          show-expand
          hide-default-footer
          @toggle-select-all="selectAllToggle"
        >
          <!-- eslint-disable-next-line -->
          <template #item.data-table-expand="{ item, isExpanded, expand }">
            <v-btn
              v-if="isExpanded"
              icon
              :data-test-id="'order_item_' + item.id + '_hide_btn'"
              @click="expand(false)"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
            <v-btn
              icon
              v-else
              :data-test-id="'order_item_' + item.id + '_expand_btn'"
              @click="expand(true)"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>

          <!-- eslint-disable-next-line -->
          <template #item.data-table-select="{ item, isSelected, select }">
            <v-simple-checkbox
              v-if="item.cancellationStatus === 'CANCELABLE'"
              v-ripple
              :value="isSelected"
              :data-test-id="'order_item_' + item.id + '_cancel_checkbox'"
              @click="select(!isSelected)"
            />

            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-on="on" v-bind="attrs" color="red" dark>
                  {{
                    item.cancellationStatus === "CANCELED"
                      ? "mdi-checkbox-marked-circle-outline"
                      : "mdi-alert-circle-outline"
                  }}
                </v-icon>
              </template>
              <div class="d-flex flex-column justify-center align-start">
                <div>Status: {{ item.cancellationStatus }}</div>
                <div v-if="item.reason">Reason: {{ item.reason }}</div>
              </div>
            </v-tooltip>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.number="{ item }">
            <span :data-test-id="'order_item_' + item.id">{{
              item.number
            }}</span>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.sku="{ item }">
            <span :data-test-id="'order_item_' + item.id + '_sku'">{{
              item.sku
            }}</span>
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.status="{ item }">
            <StatusChip :status="item.status" />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.priceInclTax="{ item }">
            {{
              $parseFractionUnitToString(
                item.priceInclTax,
                item.currency || order.currency
              )
            }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.rowTotalInclTax="{ item }">
            {{
              $parseFractionUnitToString(
                item.rowTotalInclTax,
                item.currency || order.currency
              )
            }}
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.feeAmount="{ item }">
            <v-text-field
              v-if="
                !order.feeAmount && item.cancellationStatus === 'CANCELABLE'
              "
              v-model.number="item.feeAmount"
              :data-test-id="
                'order_item_' + item.id + '_cancellation_fee_input'
              "
              :suffix="order.currency"
              :rules="[
                (v) =>
                  !v ||
                  $parsePriceToFractionUnit(v, order.currency) >= 0 ||
                  'Must be positive',
                (v) =>
                  !v ||
                  $parsePriceToFractionUnit(v, order.currency) <=
                    item.rowTotalInclTax ||
                  'Greater than item total',
              ]"
              :max="
                $parseFractionUnitToPrice(item.rowTotalInclTax, order.currency)
              "
              min="0"
              class="order-item-cancel-fee-input"
              outlined
              dense
              hide-details="auto"
              type="number"
            />
            <StatusChip
              v-else
              :status="item.cancellationStatus"
              :data-test-id="
                'order_item_' +
                item.id +
                '_cancellation_fee_status_' +
                item.cancellationStatus
              "
            />
          </template>

          <!-- eslint-disable-next-line -->
          <template v-slot:item.forceRefund="{ item }">
            <v-checkbox
              v-if="item.cancellationStatus === 'CANCELABLE'"
              v-model="item.forceRefund"
              :disabled="order.forceRefund"
              :data-test-id="'order_item_' + item.id + '_force_refund_checkbox'"
              class="force-refund-checkbox"
              hide-details
            />
            <StatusChip
              v-else
              :status="item.cancellationStatus"
              :data-test-id="
                'order_item_' +
                item.id +
                '_force_refund_status_' +
                item.cancellationStatus
              "
            />
          </template>

          <template v-slot:expanded-item="{ item }">
            <td :colspan="headers.length + 2">
              <OrderItemDetail
                :item="item"
                :order="order"
                :data-test-id="'order_item_' + item.id + '_detail'"
              />
            </td>
          </template>
        </v-data-table>
      </v-container>
      <v-container class="order-items-price-container">
        <v-card
          outlined
          class="order-items-price-card"
          data-test-id="orderItemsPriceCard"
        >
          <v-container>
            <v-row dense>
              <v-col> Subtotal: </v-col>
              <v-col data-test-id="subTotalPrice">
                {{ $parseFractionUnitToString(subTotal, order.currency) }}
              </v-col>
            </v-row>
            <v-row dense>
              <v-col> Tax: </v-col>
              <v-col>
                {{ $parseFractionUnitToString(tax, order.currency) }}
              </v-col>
            </v-row>
            <v-row dense v-if="!cancelOrder" data-test-id="refundedAmount">
              <v-col> Refunded: </v-col>
              <v-col>
                &minus;
                {{ $parseFractionUnitToString(refunded, order.currency) }}
              </v-col>
            </v-row>
            <v-row
              dense
              v-if="cancelOrder && !order.feeAmount"
              data-test-id="itemCancellationFeeAmount"
            >
              <v-col> Item Fees: </v-col>
              <v-col>
                &minus;
                {{ $parseFractionUnitToString(itemFees, order.currency) }}
              </v-col>
            </v-row>
            <v-row dense v-if="cancelOrder">
              <v-col>
                Cancellation Fee:
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on" x-small>
                      <v-icon>mdi-help-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span
                    >Setting a cancellation fee on the order overrides fees set
                    on single items</span
                  >
                </v-tooltip>
              </v-col>
              <v-col>
                <v-text-field
                  v-model.number="order.feeAmount"
                  :suffix="order.currency"
                  :rules="[
                    (v) =>
                      !v ||
                      $parsePriceToFractionUnit(v, order.currency) >= 0 ||
                      'Must be positive',
                    (v) =>
                      !v ||
                      totalExclFee == 0 ||
                      $parsePriceToFractionUnit(v, order.currency) <=
                        totalExclFee ||
                      'Greater than cancellation total',
                  ]"
                  :max="$parseFractionUnitToPrice(totalExclFee, order.currency)"
                  min="0"
                  class="order-cancel-fee-input"
                  outlined
                  dense
                  hide-details="auto"
                  type="number"
                  data-test-id="orderCancellationFeeInput"
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row>
              <v-col>
                {{ "Total" + (cancelOrder ? " Refund" : "") + ":" }}
              </v-col>
              <v-col data-test-id="totalPrice">
                <b>{{
                  $parseFractionUnitToString(totalInclFee, order.currency)
                }}</b>
              </v-col>
            </v-row>
            <v-row> </v-row>
            <v-row v-if="cancelOrder">
              <v-col> Force cancellation </v-col>
              <v-col>
                <v-checkbox
                  v-model="order.forceRefund"
                  class="force-refund-checkbox"
                  hide-details
                  data-test-id="orderForceRefundCheckbox"
                />
              </v-col>
            </v-row>
            <v-row v-if="cancelOrder">
              <v-col> Without payment refund </v-col>
              <v-col>
                <v-checkbox
                  v-model="order.doOffline"
                  class="offline-refund-checkbox"
                  hide-details
                  data-test-id="orderWithoutRefundCheckbox"
                />
              </v-col>
            </v-row>
            <v-row v-if="cancelOrder">
              <v-col></v-col>
              <v-col>
                <v-btn
                  color="red"
                  data-test-id="cancelItemsBtn"
                  :loading="loading"
                  :disabled="loading || selectedItems.length == 0"
                  @click="cancel"
                >
                  Cancel Items
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-container>
    </v-form>

    <v-dialog v-model="showResponse" persistent max-width="1000px">
      <CanceledOrderViewer
        :canceled-order="cancelResponse"
        :order="order"
        :key="order.id"
        @close="closeCanceledOrderViewer"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import OrderItemDetail from "./OrderItemDetail";
import CanceledOrderViewer from "./CanceledOrderViewer";
import StatusChip from "../../common/display-helpers/StatusChip";

export default {
  props: ["order", "cancellations"],

  components: {
    OrderItemDetail,
    CanceledOrderViewer,
    StatusChip,
  },

  data() {
    return {
      expanded: [],
      cancelOrder: false,
      selectedItems: [],
      loading: false,
      cancelResponse: null,
      showResponse: false,
      validateCancellation: false,
    };
  },

  watch: {
    "order.forceRefund": function () {
      if (this.order.forceRefund) {
        this.order.items.forEach((item) => (item.forceRefund = true));
      }
    },
  },

  methods: {
    async enableCancellation() {
      try {
        this.validateCancellation = true;
        let validationResponse = await this.$store.dispatch("post", {
          path: "/order/" + this.order.id + "/cancelValidate",
          successMsg: null,
        });

        let validation = await validationResponse.json();

        validation?.orderItems?.forEach((item) => {
          let orderItem = this.order.items.find((oi) => oi.id === item.id);
          if (!orderItem) return;

          this.$set(orderItem, "feeAmount", item.cancellationFee);
          this.$set(orderItem, "cancellationStatus", item.status);
          this.$set(orderItem, "reason", item.reason);
        });

        this.cancelOrder = true;
      } finally {
        this.validateCancellation = false;
      }
    },

    async cancel() {
      try {
        if (!this.$refs.cancellationForm.validate()) return;
        if (
          !(await this.$confirm(
            "Cancel items?",
            "Do you really want to cancel the selected order items?"
          ))
        )
          return;

        this.loading = true;

        let body = {
          createdBy: "Admin",
          feeAmount: this.$parsePriceToFractionUnit(
            this.order.feeAmount,
            this.order.currency
          ),
        };

        if (this.order.doOffline)
          this.$set(body, "doOffline", this.order.doOffline);

        if (this.selectedItems.length > 0) {
          body.orderItems = [];
          this.selectedItems.forEach((item) => {
            let orderItem = {
              id: item.id,
            };
            if (body.feeAmount) this.$delete(item, "feeAmount");
            if (item.forceRefund)
              this.$set(orderItem, "forceRefund", item.forceRefund);
            if (item.feeAmount)
              this.$set(
                orderItem,
                "feeAmount",
                this.$parsePriceToFractionUnit(
                  item.feeAmount,
                  this.order.currency
                )
              );
            body.orderItems.push(orderItem);
          });
        }

        let res = await this.$store.dispatch("post", {
          path: "/order/" + this.order.id + "/cancel",
          body,
          successMsg: null,
        });

        if (!res?.ok) return;

        this.cancelResponse = await res.json();
        this.showResponse = true;
      } finally {
        this.loading = false;
      }

      return;
    },

    selectAllToggle({ items, value }) {
      if ((this.selectedItems.length > 0 && value) || !value) {
        this.selectedItems = [];
        return;
      }

      this.selectedItems = [];
      items.forEach((item) => {
        if (item.cancellationStatus === "CANCELABLE") {
          this.selectedItems.push(item);
        }
      });
    },

    closeCanceledOrderViewer() {
      this.$emit("reload");
      this.cancelOrder = false;
      this.selectedItems = [];
      this.showResponse = false;
    },
  },

  computed: {
    headers() {
      let headers = [
        { text: "Item Nr.", value: "number" },
        { text: "Product", value: "sku" },
      ];

      if (this.cancelOrder) {
        headers = headers.concat([
          { text: "Quantity", value: "qty" },
          { text: "Total", value: "rowTotalInclTax" },
        ]);

        if (!this.order.feeAmount)
          headers.push({
            text: "Cancellation Fee",
            value: "feeAmount",
            sortable: false,
          });
        headers.push({
          text: "Force cancellation",
          value: "forceRefund",
          sortable: false,
        });
        return headers;
      }

      return headers.concat([
        { text: "Status", value: "status" },
        { text: "Product Type", value: "productType" },
        { text: "Price", value: "priceInclTax" },
        { text: "Quantity", value: "qty" },
        { text: "Total", value: "rowTotalInclTax" },
      ]);
    },

    isCancelable() {
      //may items or the whole order be canceled?
      return (
        (this.order.productionStatus == "FAILED" ||
          this.order.productionStatus == "COMPLETE") &&
        this.order.orderStatus != "CANCELED"
      );
    },

    subTotal() {
      //return either the total excl. tax of the order or, in cancel view, of all selected items
      if (this.cancelOrder)
        return this.selectedItems
          .map((item) => item.rowTotalExclTax)
          .reduce((a, b) => a + b, 0);
      return this.order.items
        .map((item) => item.rowTotalExclTax)
        .reduce((a, b) => a + b, 0);
    },

    itemFees() {
      //calculate sum of all item cancellation fees
      return this.$parsePriceToFractionUnit(
        this.selectedItems
          .map((item) => item.feeAmount || 0)
          .reduce((a, b) => a + b, 0),
        this.order.currency
      );
    },

    refunded() {
      //TODO: Take cancellation fees in account
      //return the combined price of all canceled items
      return this.cancellations
        .map(
          (cancellation) =>
            cancellation.totalInclTax - (cancellation.feeAmount || 0)
        )
        .reduce((a, b) => a + b, 0);
    },

    totalExclFee() {
      //return either the total of all order items combined or, in cancel view, of all selected items without fee amounts
      if (this.cancelOrder)
        return this.selectedItems
          .map((item) => item.priceInclTax)
          .reduce((a, b) => a + b, 0);

      return this.order.items
        .filter((item) => item.cancellationStatus === "CANCELABLE")
        .map((item) => item.priceInclTax)
        .reduce((a, b) => a + b, 0);
    },

    totalInclFee() {
      //return either the total of all order items combined or, in cancel view, of all selected items (incl. fee amounts)
      if (this.cancelOrder) {
        //calculate the total in cancel view and take fee amounts of the order or single items in account
        let itemsTotal = this.selectedItems
          .map((item) => item.rowTotalInclTax)
          .reduce((a, b) => a + b, 0);
        if (this.order.feeAmount) {
          itemsTotal -= this.$parsePriceToFractionUnit(
            this.order.feeAmount,
            this.order.currency
          );
        } else {
          itemsTotal -= this.$parsePriceToFractionUnit(
            this.selectedItems
              .map((item) => item.feeAmount || 0)
              .reduce((a, b) => a + b, 0),
            this.order.currency
          );
        }

        return itemsTotal > 0 ? itemsTotal : 0;
      }

      return (
        this.order.items
          .map((item) => item.priceInclTax)
          .reduce((a, b) => a + b, 0) - this.refunded
      );
    },

    tax() {
      //return ether the tax amounts of all order items combined or, in cancel view, of all selected items
      if (this.cancelOrder)
        return this.selectedItems
          .map((item) => item.taxAmount)
          .reduce((a, b) => a + b, 0);
      return this.order.items
        .map((item) => item.taxAmount)
        .reduce((a, b) => a + b, 0);
    },
  },
};
</script>

<style scoped>
.order-items-container {
  height: calc(100% - 48px);
  padding: 0;
}

.order-items-price-container {
  display: flex;
  padding: 12px 0 0 0;
  justify-content: flex-end;
}

.order-items-price-card {
  width: 40%;
}

.order-items-price-card .v-divider {
  margin: 10px 0 10px 0;
}

.order-item-sku {
  flex-grow: 2;
}

.order-items-table {
  height: 50%;
  overflow-y: scroll;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 5px;
  padding: 12px 0 0 0;
}

.orders-limit-select {
  max-width: 70px;
  margin-right: 5px;
}

.force-refund-checkbox,
.offline-refund-checkbox {
  margin-top: 0;
  padding-top: 0;
}

.order-items-table
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded__row {
  background-color: var(--v-psblue-base);
  color: white;
}

.order-items-table
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded__row:hover {
  color: black;
}
</style>
