<template>
  <MasterDetail data-test-id="orderOverview" :detail-open="!!selectedOrder">
    <template #toolbar>
      <Toolbar
        v-model="filterQuery"
        class="order-table-toolbar"
        :search-props="{
          possibleFilters,
          disableFullTextSearch: true,
        }"
        :key="possibleFilters.length"
        @update-filters="(updated) => (filters = updated)"
      >
        <template #extended>
          <div class="extended-toolbar">
            <v-select
              class="orders-limit-select"
              dense
              hide-details
              v-model="limit"
              :items="pageLimits"
              :disabled="loadingOrders"
              data-test-id="pageLimitSelector"
            />
            rows
            <v-divider class="mx-4" vertical></v-divider>

            <!-- CUSTOM PAGINATION COMPONENT -->
            <PaginationComponent
              v-if="totalPages > 0"
              v-model="currentPage"
              :totalPages="totalPages"
              :disabled="loadingOrders"
            />
            <v-divider class="mx-4" v-if="totalPages > 0" vertical></v-divider>
            <span v-if="total">{{ total }} total</span>
            <v-spacer></v-spacer>
          </div>
        </template>
      </Toolbar>
    </template>

    <template #table>
      <v-data-table
        :options.sync="options"
        dense
        disable-pagination
        hide-default-footer
        fixed-header
        data-test-id="orderTable"
        class="orders-table"
        :items="orders"
        :headers="headers"
        :loading="loadingOrders"
        :server-items-length="total"
        :single-select="true"
        :multi-sort="true"
        :height="tableHeight"
      >
        <template v-slot:item="{ item }">
          <tr
            @click="showOrder(item.id)"
            :class="{
              selected: selectedOrder && item.id === selectedOrder.id,
            }"
            :data-test-id="'order_' + item.id"
          >
            <td :data-test-id="'order_number_' + item.id">
              {{ item.number }}
            </td>
            <td :data-test-id="'order_createdAt_' + item.id">
              {{ $getLocalizedDate(item.createdAt) }}
            </td>
            <td :data-test-id="'order_shippingAddress_' + item.id">
              {{
                item.shippingAddress
                  ? item.shippingAddress.firstname +
                    " " +
                    item.shippingAddress.lastname
                  : ""
              }}
            </td>
            <td :data-test-id="'order_billingAddress_' + item.id">
              {{
                item.billingAddress
                  ? item.billingAddress.firstname +
                    " " +
                    item.billingAddress.lastname
                  : ""
              }}
            </td>

            <v-menu
              open-on-hover
              right
              offset-x
              nudge-left="30"
              close-delay="100"
              class="order-item-menu"
            >
              <ul class="order-item-list">
                <li v-for="(product, index) in item.items" :key="index">
                  <a
                    :href="
                      $router.resolve({
                        name: 'productDetail',
                        params: { product: product.sku },
                      }).href
                    "
                    target="_blank"
                  >
                    {{ product.sku }}
                  </a>
                </li>
              </ul>

              <template v-slot:activator="{ on, attrs }">
                <td v-on="on" v-bind="attrs">
                  <ul class="order-item-list">
                    <li
                      v-for="(product, index) in item.items.slice(0, 3)"
                      :key="index"
                    >
                      {{ product.sku }}
                    </li>
                    <li v-if="item.items.length > 3">
                      &plus; {{ item.items.length - 3 }} more
                    </li>
                  </ul>
                </td>
              </template>
            </v-menu>
            <!-- eslint-disable-next-line -->
            <td
              v-for="(status, index) in [
                'orderStatus',
                'paymentStatus',
                'productionStatus',
              ]"
              :key="index"
            >
              <StatusChip :status="item[status]" />
            </td>
            <td>
              {{ $parseFractionUnitToString(item.totalInclTax, item.currency) }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </template>

    <template #detail>
      <OrderDetail
        v-if="selectedOrder"
        :key="selectedOrder.id"
        :order="selectedOrder"
        :style="{
          height: detailHeight + 'px',
        }"
        @reload="reloadPage"
        @close="closeOrderDetail"
      />
    </template>
  </MasterDetail>
</template>

<script>
import MasterDetail from "../common/templates/MasterDetail";
import Toolbar from "../common/templates/Toolbar";
import PaginationComponent from "../PaginationComponent.vue";
import OrderDetail from "./OrderDetail";
import StatusChip from "../common/display-helpers/StatusChip";
import mainOverviewMixin from "../../mixins/main-overview-mixin";
import sortQueryMixin from "mixins/sort-query-mixin";

export default {
  mixins: [mainOverviewMixin, sortQueryMixin],

  props: {
    customer: {
      type: String,
      required: false,
    },

    openDetailsInTab: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  components: {
    MasterDetail,
    Toolbar,
    OrderDetail,
    PaginationComponent,
    StatusChip,
  },

  data() {
    return {
      orders: [],
      currentPage: 1,
      total: 0,
      filters: {},
      limit: 20,
      loadingOrders: false,
      selectedOrder: null,
      filterQuery: {},
      options: {
        sortBy: ["number"],
        sortDesc: [true],
      },
    };
  },

  async created() {
    await this.init();
  },

  watch: {
    filterQuery: {
      handler: async function (filterQuery) {
        if (this.$route.name === "customerDetail") {
          await this.loadOrders();
        } else {
          let query = Object.assign({}, this.$route.query);
          const oldFilterQuery = query.filter;
          //remove filter query if the query is faulty
          if (Object.keys(filterQuery).length > 0) {
            let copy = this.$cloneObject(filterQuery);
            if (this.$isPlainObject(copy)) {
              copy = JSON.stringify(copy);
            }
            query.filter = this.$urlEncode(copy);
          } else {
            delete query.filter;
          }
          await this.$router.push({ ...this.$route, query });
          if (oldFilterQuery !== query.filter) this.loadOrders();
        }
      },
      deep: true,
    },

    async currentPage(page) {
      //reload customers if the current page changes
      if (page < 1) page = 1;

      if (page > 0 && page <= this.totalPages) {
        await this.loadOrders();
        if (this.$route.name !== "customerDetail") {
          await this.$router.push({
            ...this.$route,
            query: Object.assign({}, this.$route.query, {
              page: this.currentPage,
            }),
          });
        }
      }
    },

    totalPages(totalPages) {
      if (this.currentPage > totalPages) {
        this.currentPage = totalPages;
      }
    },

    async limit(limit) {
      if (this.$route.name !== "customerDetail") {
        await this.$router.push({
          ...this.$route,
          query: Object.assign({}, this.$route.query, { limit }),
        });
      }

      if (this.totalPages === 0) return;

      //changed the limit of entries on a page, therefore recalculate current pages
      if (this.totalPages > 0 && this.currentPage > this.totalPages) {
        //change of current page triggers a reload
        this.currentPage = this.totalPages;
      } else {
        //current page did not change, so reload customers manually
        await this.loadOrders();
      }
    },

    async options(options, old) {
      if (this.$route.name !== "customerDetail") {
        if (
          this.$isEqual(options.sortBy, old?.sortBy) &&
          this.$isEqual(options.sortDesc, old?.sortDesc)
        ) {
          //If sortBy and sortDesc did not change, do not update the query
          return;
        }
        let query = Object.assign({}, this.$route.query);
        const sort = this.parseOptionsToSortQuery(options);
        if (!sort) this.$delete(query, "sort");
        else this.$set(query, "sort", sort);
        await this.$router.push({ ...this.$route, query });
        await this.loadOrders();
      }
    },
  },

  methods: {
    async init() {
      //If the code is changed here, check in ProductOverview and CustomerPage if it also needs changing there

      //initialize the component with the route parameters
      const namedRoute = this.$route.name;
      const routeQuery = this.$route.query;

      //get the query parameters and update the according variables
      let page = routeQuery.page;
      let limit = routeQuery.limit;
      let filter = routeQuery.filter;
      let sort = routeQuery.sort;

      let pageChange = false;

      if (namedRoute !== "customerDetail") {
        if (page) {
          page = parseInt(page, 10);
          if (Number.isNaN(page)) page = 1;
          pageChange = page != this.currentPage;
          this.currentPage = page;
        }

        if (limit) {
          limit = parseInt(limit, 10);
          if (Number.isNaN(limit) || !this.pageLimits.includes(limit))
            limit = this.pageLimits[0];
          this.limit = limit;
        }

        if (filter) {
          try {
            filter = this.$urlDecode(filter);
            this.filterQuery = JSON.parse(filter);
          } catch (e) {
            this.filterQuery = null;
            console.warn(e);
          }
        }

        if (sort) {
          const options = this.parseSortQueryToOptions(sort);
          this.options = Object.assign(this.options, options);
        }

        let query = {};
        if (this.currentPage) {
          query.page = this.currentPage;
        }
        if (this.limit) {
          query.limit = this.limit;
        }
        if (this.filterQuery) {
          query.filter = this.$urlEncode(JSON.stringify(this.filterQuery));
        }
        if (
          this.options.sortBy?.length > 0 &&
          this.options.sortDesc?.length > 0
        ) {
          query.sort = this.parseOptionsToSortQuery(this.options);
        }

        //update the URL query
        await this.$router.replace({ ...this.$route, query });
      }
      if (
        this.customer ||
        namedRoute === "orders" ||
        (namedRoute === "orderDetail" && (!this.selectedOrder || pageChange))
      ) {
        //load orders if the route is correct or the order detail is accessed via URL
        //if a filter is set as query parameter, do not load it here, because the orders are already reloaded when changing the filter
        await this.loadOrders();
      }

      if (namedRoute === "orderDetail") {
        //show customer detail of requested customer
        const orderId = this.$route.params.order;
        if (!orderId) {
          this.closeOrderDetail();
        } else if (!this.selectedOrder || orderId != this.selectedOrder.id) {
          this.showOrder(orderId);
        }
      }
    },

    async loadOrders() {
      //load current page of customers
      let offset =
        this.limit * (this.currentPage > 0 ? this.currentPage - 1 : 0);
      //build request body
      let body = {
        filter: {},
        sort: [],
        offset,
        limit: this.limit,
        total: true,
      };

      //add filters
      if (this.customer) body.filter.customerId = this.customer;

      for (let index in this.filters) {
        const filter = this.filters[index];
        const property = filter.property;
        const value = filter.value;
        const operator = filter.operator;
        if (property === "price" || property === "date") {
          body.filter[property] = {
            [operator]: value,
          };
        } else if (property === "externalOrderNumber") {
          body.filter.searchText = {
            text: value,
            fields: [
              {
                property: "productionAttributes",
                attributeKey: property,
                operator,
              },
            ],
          };
        } else {
          body.filter[property] = value;
        }
      }

      //add sorting
      let sortBy = this.options?.sortBy;
      let sortDesc = this.options?.sortDesc;
      if (Array.isArray(sortBy)) {
        for (let i = 0; i < sortBy.length; i++) {
          body.sort.push({
            //parse property name to comply with the spec enums e.g. shippingAddressName => SHIPPING_ADDRESS_NAME
            property: sortBy[i]
              .replace(/([a-z])([A-Z])/g, "$1_$2")
              .toUpperCase(),
            desc: sortDesc[i] ?? false,
          });
        }
      }

      this.loadingOrders = true;
      try {
        const orderRes = await this.$store.$coreApi.coreOrderApi.searchOrders(
          this.selectedDomain,
          body
        );

        if (!orderRes?.ok) return;

        const orderData = await orderRes.json();
        this.orders = orderData.result;
        this.total = orderData.total;

        if (this.currentPage > this.totalPages)
          this.currentPage = this.totalPages;
      } finally {
        this.loadingOrders = false;
      }
    },

    async showOrder(orderId) {
      if (this.openDetailsInTab) {
        const routeData = this.$router.resolve({
          name: "orderDetail",
          params: { order: orderId },
        });
        window.open(routeData.href, "_blank");
        return;
      }

      this.loadingOrders = true;

      try {
        this.selectedOrder = await this.$store.$coreApi.coreOrderApi.getOrder(
          this.selectedDomain,
          orderId
        );

        if (!this.selectedOrder) {
          this.loadingOrders = false;
          this.closeOrderDetail();
          return;
        }

        if (!this.customer) {
          await this.$router.push({
            name: "orderDetail",
            params: { order: orderId },
            query: this.$route.query,
          });
        }
      } finally {
        this.loadingOrders = false;
      }
    },

    async closeOrderDetail() {
      this.selectedOrder = null;
      if (!this.customer)
        await this.$router.push({
          name: "orders",
          query: this.$route.query,
        });
    },

    async reloadPage() {
      if (this.selectedOrder) await this.showOrder(this.selectedOrder.id);
      await this.loadOrders();
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    possibleFilters() {
      let filters = [
        { text: "Order Nr.", property: "number", type: "text" },
        { text: "First Name", property: "firstname", type: "text" },
        { text: "Last Name", property: "lastname", type: "text" },
        { text: "Created at", property: "date", type: "date", dateOnly: true },
        { text: "Price", property: "price", type: "number" },
        { text: "SKU", property: "sku", type: "text" },
        {
          text: "Order Status",
          property: "orderStatus",
          type: "list",
          options: this.orderStates,
        },
        {
          text: "Payment Status",
          property: "paymentStatus",
          type: "list",
          options: this.paymentStates,
        },
        {
          text: "Production Status",
          property: "productionStatus",
          type: "list",
          options: this.paymentStates,
        },
        {
          text: "Payment Transaction ID",
          property: "paymentTxId",
          type: "text",
        },
        {
          text: "External Order Nr.",
          property: "externalOrderNumber",
          type: "text",
          operatorOptions: [
            { text: "is", value: "EQ" },
            { text: "like", value: "LIKE" },
          ],
        },
      ];

      if (!this.customer)
        filters.push({
          text: "Customer-ID",
          property: "customerId",
          type: "text",
        });

      return filters;
    },

    orderStates() {
      return [
        { text: "NEW", value: "NEW" },
        { text: "COMPLETE", value: "COMPLETE" },
        { text: "CONFIRMED", value: "CONFIRMED" },
        { text: "CANCELED", value: "CANCELED" },
      ];
    },

    paymentStates() {
      return [
        { text: "NEW", value: "NEW" },
        { text: "COMPLETE", value: "COMPLETE" },
        { text: "FAILED", value: "FAILED" },
        { text: "PENDING", value: "PENDING" },
      ];
    },

    headers() {
      return [
        { text: "Order Nr.", value: "number" },
        { text: "Created At", value: "createdAt" },
        { text: "Shipping to", value: "shippingAddressName" },
        { text: "Bill to", value: "billingAddressName" },
        { text: "Items", value: "items", sortable: false },
        { text: "Order Status", value: "orderStatus" },
        { text: "Payment Status", value: "paymentStatus" },
        { text: "Production Status", value: "productionStatus" },
        { text: "Price", value: "totalInclTax" },
      ];
    },

    sortableFields() {
      return {
        number: true,
        createdAt: true,
        shippingAddressName: true,
        billingAddressName: true,
        orderStatus: true,
        paymentStatus: true,
        productionStatus: true,
        totalInclTax: true,
      };
    },

    totalPages() {
      return Math.ceil(this.total / this.limit);
    },

    pageLimits() {
      return [20, 50, 100];
    },
  },
};
</script>

<style scoped>
.order-table-toolbar .extended-toolbar {
  display: flex;
  height: 70px;
  align-items: center;
  padding: 0 12px 8px 12px;
}

.orders-table.v-data-table,
.orders-table.v-data-table::v-deep > .v-data-table__wrapper {
  width: 100%;
}

.orders-limit-select {
  max-width: 70px;
  margin-right: 5px;
}

.order-item-list {
  list-style-type: none;
}

.v-menu__content > .order-item-list {
  background-color: white;
  padding: 12px;
}

td > .order-item-list > li {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.orders-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.selected {
  background-color: var(--v-psblue-base);
  color: white;
}

.orders-table.v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.selected:hover {
  color: black;
}
</style>

<style>
.v-application .orders-table td {
  white-space: nowrap;
}
</style>
