<template>
  <v-card outlined class="order-history" data-test-id="orderHistoryCard">
    <v-card-title>
      <v-icon left>mdi-history</v-icon>
      <div>History</div>
      <v-spacer />
      <div v-if="runningAction" class="px-4">
        <v-progress-circular
          indeterminate
          :size="24"
          :width="2"
          color="grey darken-3"
        />
      </div>
      <v-btn
        color="primary"
        :disabled="createComment || runningAction"
        @click="toggleCreate(true)"
        data-test-id="addOrderHistoryBtn"
      >
        Add Comment
      </v-btn>
    </v-card-title>
    <v-container
      :class="{
        'order-timeline-container': true,
        'empty-history': history.length === 0,
      }"
    >
      <v-timeline dense v-if="history.length > 0 || createComment">
        <v-timeline-item
          v-if="createComment"
          color="psgreen"
          fill-dot
          icon="mdi-message-text"
          :data-test-id="'history_entry_new'"
        >
          <v-card elevation="4" data-test-id="OrderHistoryEntryNew">
            <v-card-subtitle class="history-comment-subtitle font-weight-bold">
              <div class="d-flex flex-row">
                <div class="d-flex align-center">
                  {{ "#" + (history.length + 1) }}
                </div>
                <v-spacer />
                <v-btn
                  class="mx-2"
                  color="green"
                  data-test-id="commentCreateBtn"
                  :disabled="runningAction"
                  @click="saveComment"
                >
                  CREATE
                </v-btn>
                <v-btn
                  text
                  data-test-id="commentCancelBtn"
                  :disabled="runningAction"
                  @click="toggleCreate(false)"
                >
                  CANCEL
                </v-btn>
              </div>
            </v-card-subtitle>
            <v-card-text class="history-comment-text">
              <v-form ref="commentForm">
                <v-input
                  :value="newComment"
                  :rules="commentRules"
                  class="d-flex"
                >
                  <textarea
                    ref="editor"
                    placeholder="Add new comment (you may use HTML)..."
                    data-test-id="historyCommentInput"
                  />
                </v-input>
              </v-form>
              <!-- New comment preview -->
              <div
                v-html="sanitize(newComment)"
                class="history-comment show-all pt-3"
                data-test-id="historyCommentPreview"
              />
            </v-card-text>
          </v-card>
        </v-timeline-item>

        <v-timeline-item
          v-for="(entry, index) in history"
          :color="getEntryColor(entry)"
          icon="mdi-message-text"
          fill-dot
          :key="index"
          :data-test-id="'history_entry_row_' + index"
        >
          <v-card elevation="4">
            <v-card-subtitle class="history-comment-subtitle font-weight-bold">
              {{
                "#" +
                (history.length - index) +
                ", " +
                $getLocalizedDate(entry.createdAt)
              }}
            </v-card-subtitle>
            <v-card-text class="history-comment-text">
              <div
                v-html="sanitize(entry.comment, entry)"
                :ref="'comment_' + entry.id"
                :class="{
                  'history-comment': true,
                  'show-all': showAll[entry.id],
                  'has-more': hasMore[entry.id],
                }"
                :data-test-id="'history_entry_' + index + '_comment'"
              />
              <div class="d-flex justify-center">
                <a
                  v-if="hasMore[entry.id]"
                  href="javascript:void(0)"
                  class="py-2"
                  :data-test-id="'history_entry_' + index + '_toggle_show_btn'"
                  @click="toggleShowAll(entry)"
                >
                  {{ showAll[entry.id] ? "Show Less" : "Show More" }}
                </a>
              </div>
            </v-card-text>
          </v-card>
        </v-timeline-item>
      </v-timeline>
      <v-subheader v-else data-test-id="emptyHistoryHint">
        No History available
      </v-subheader>
    </v-container>
  </v-card>
</template>

<script>
import CodeMirror from "codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/xq-light.css";
import "codemirror/mode/xml/xml.js";
import "codemirror/addon/display/placeholder.js";
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      history: [],
      runningAction: false,
      hasMore: {},
      showAll: {},
      createComment: false,
      newComment: null,
      editor: null,
    };
  },

  watch: {
    runningAction(isRunning) {
      if (!this.editor) return;
      this.editor.setOption("readOnly", isRunning ? "nocursor" : false);
    },
  },

  mounted() {
    this.loadHistory();
  },

  methods: {
    async loadHistory() {
      try {
        this.runningAction = true;
        this.history = await this.$store.$coreApi.coreOrderApi.getHistory(
          this.selectedDomain,
          this.order.id
        );
      } finally {
        this.runningAction = false;
      }
    },

    toggleShowAll(entry) {
      const isShown = this.showAll[entry.id];
      this.$set(this.showAll, entry.id, !isShown);
    },

    toggleCreate(create) {
      this.createComment = create;
      if (create) {
        this.$nextTick(() => {
          //create a new editor instance and set options
          this.editor = CodeMirror.fromTextArea(this.$refs.editor, {
            height: "auto",
            theme: "xq-light",
            mode: {
              name: "xml",
              html: true,
              statementIndent: 2,
            },
          });
          this.editor.on(
            "keyup",
            (editor) => (this.newComment = editor.getValue())
          );

          this.editor.setSize("100%", 200);
          //Focus the text area
          this.editor.focus();
        });
      } else {
        this.editor = null;
        this.newComment = null;
      }
    },

    async saveComment() {
      try {
        if (!this.$refs.commentForm.validate()) return;
        this.runningAction = true;
        const sanitizedComment = this.sanitize(this.newComment);
        const res = await this.$store.$coreApi.coreOrderApi.createHistory(
          this.selectedDomain,
          this.order.id,
          sanitizedComment
        );
        if (!res?.ok) return;
        await this.loadHistory();
        this.toggleCreate(false);
      } finally {
        this.runningAction = false;
      }
    },

    sanitize(comment, entry) {
      if (entry && !this.hasMore?.[entry.id]) {
        this.$nextTick(() => {
          //After the input is sanitized, check if the "Show More" button
          //has to be displayed
          const ref = "comment_" + entry.id;
          const element = this.$refs?.[ref]?.[0];
          //calculate through the client and scroll height of the element, if
          //the comment is truncated. If yes, display the "Show More" button
          this.$set(
            this.hasMore,
            entry.id,
            element && element.clientHeight < element.scrollHeight
          );
        });
      }

      //Do not allow styling in comments to prevent breaking the page
      //layout
      return this.$sanitize(comment, {
        FORBID_TAGS: ["style", "input", "textarea", "button", "form"],
        FORBID_ATTR: ["style", "class"],
      });
    },

    getEntryColor(entry) {
      const level = entry.level;
      switch (level) {
        case "WARN":
          return "warning";
        case "ERROR":
          return "error";
        default:
          return "psgreen";
      }
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    commentRules() {
      return [
        (value) => !!value || "Comment is required",
        (value) =>
          (value && value.length >= 1) ||
          "Comment must have at least 1 character",
        (value) =>
          value?.length <= 5000 ||
          "Comment must have not more than 5000 characters",
        (value) => {
          const sanitizedComment = this.sanitize(value);
          return (
            !!sanitizedComment || "Comment does not contain any valid input"
          );
        },
      ];
    },
  },
};
</script>
<style scoped>
.order-history {
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
}

.order-history .v-input::v-deep .CodeMirror {
  border: 1px solid rgba(0, 0, 0, 0.19);
  border-radius: 6px;
  padding: 4px;
}

.order-history .v-input.error--text::v-deep .CodeMirror {
  border-color: var(--v-error-base);
}

.order-history::v-deep .CodeMirror pre.CodeMirror-placeholder {
  color: #999;
}

.order-history::v-deep .history-comment {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  position: relative;
  max-height: 70px; /* equals 3 lines of text */
}

.order-history::v-deep .history-comment.has-more:not(.show-all)::before {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: linear-gradient(transparent 30px, white);
}

.order-history::v-deep .history-comment.show-all {
  overflow: visible;
  max-height: unset;
}

.order-history .history-comment-subtitle {
  padding: 8px;
}

.order-history .history-comment-text {
  padding: 0 8px 8px 8px;
}
</style>