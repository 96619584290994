var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"order-history",attrs:{"outlined":"","data-test-id":"orderHistoryCard"}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-history")]),_c('div',[_vm._v("History")]),_c('v-spacer'),(_vm.runningAction)?_c('div',{staticClass:"px-4"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":24,"width":2,"color":"grey darken-3"}})],1):_vm._e(),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.createComment || _vm.runningAction,"data-test-id":"addOrderHistoryBtn"},on:{"click":function($event){return _vm.toggleCreate(true)}}},[_vm._v(" Add Comment ")])],1),_c('v-container',{class:{
      'order-timeline-container': true,
      'empty-history': _vm.history.length === 0,
    }},[(_vm.history.length > 0 || _vm.createComment)?_c('v-timeline',{attrs:{"dense":""}},[(_vm.createComment)?_c('v-timeline-item',{attrs:{"color":"psgreen","fill-dot":"","icon":"mdi-message-text","data-test-id":'history_entry_new'}},[_c('v-card',{attrs:{"elevation":"4","data-test-id":"OrderHistoryEntryNew"}},[_c('v-card-subtitle',{staticClass:"history-comment-subtitle font-weight-bold"},[_c('div',{staticClass:"d-flex flex-row"},[_c('div',{staticClass:"d-flex align-center"},[_vm._v(" "+_vm._s("#" + (_vm.history.length + 1))+" ")]),_c('v-spacer'),_c('v-btn',{staticClass:"mx-2",attrs:{"color":"green","data-test-id":"commentCreateBtn","disabled":_vm.runningAction},on:{"click":_vm.saveComment}},[_vm._v(" CREATE ")]),_c('v-btn',{attrs:{"text":"","data-test-id":"commentCancelBtn","disabled":_vm.runningAction},on:{"click":function($event){return _vm.toggleCreate(false)}}},[_vm._v(" CANCEL ")])],1)]),_c('v-card-text',{staticClass:"history-comment-text"},[_c('v-form',{ref:"commentForm"},[_c('v-input',{staticClass:"d-flex",attrs:{"value":_vm.newComment,"rules":_vm.commentRules}},[_c('textarea',{ref:"editor",attrs:{"placeholder":"Add new comment (you may use HTML)...","data-test-id":"historyCommentInput"}})])],1),_c('div',{staticClass:"history-comment show-all pt-3",attrs:{"data-test-id":"historyCommentPreview"},domProps:{"innerHTML":_vm._s(_vm.sanitize(_vm.newComment))}})],1)],1)],1):_vm._e(),_vm._l((_vm.history),function(entry,index){return _c('v-timeline-item',{key:index,attrs:{"color":_vm.getEntryColor(entry),"icon":"mdi-message-text","fill-dot":"","data-test-id":'history_entry_row_' + index}},[_c('v-card',{attrs:{"elevation":"4"}},[_c('v-card-subtitle',{staticClass:"history-comment-subtitle font-weight-bold"},[_vm._v(" "+_vm._s("#" + (_vm.history.length - index) + ", " + _vm.$getLocalizedDate(entry.createdAt))+" ")]),_c('v-card-text',{staticClass:"history-comment-text"},[_c('div',{ref:'comment_' + entry.id,refInFor:true,class:{
                'history-comment': true,
                'show-all': _vm.showAll[entry.id],
                'has-more': _vm.hasMore[entry.id],
              },attrs:{"data-test-id":'history_entry_' + index + '_comment'},domProps:{"innerHTML":_vm._s(_vm.sanitize(entry.comment, entry))}}),_c('div',{staticClass:"d-flex justify-center"},[(_vm.hasMore[entry.id])?_c('a',{staticClass:"py-2",attrs:{"href":"javascript:void(0)","data-test-id":'history_entry_' + index + '_toggle_show_btn'},on:{"click":function($event){return _vm.toggleShowAll(entry)}}},[_vm._v(" "+_vm._s(_vm.showAll[entry.id] ? "Show Less" : "Show More")+" ")]):_vm._e()])])],1)],1)})],2):_c('v-subheader',{attrs:{"data-test-id":"emptyHistoryHint"}},[_vm._v(" No History available ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }