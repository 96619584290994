<template>
  <v-card data-test-id="canceledOrderViewer">
    <v-card-title class="cancel-order-title">
      Result
      <v-divider class="mx-4" inset vertical></v-divider>
      <v-spacer></v-spacer>
      <v-btn
        text
        @click="$emit('close')"
        data-test-id="closeCanceledOrderViewerBtn"
      >
        Close
      </v-btn>
    </v-card-title>
    <v-container>
      <v-row>
        <v-col cols="6">
          <v-card flat outlined class="canceled-order-card">
            <v-card-title>Order {{ order.number }}</v-card-title>
            <v-container class="canceled-order-status-container">
              <v-sheet class="canceled-order-status-sheet">
                <v-subheader>ITEM STATUS</v-subheader>
                <StatusChip
                  :status="
                    canceledItems === canceledOrder.items.length
                      ? 'COMPLETE'
                      : 'FAILED'
                  "
                  :text="canceledItems + '/' + canceledOrder.items.length"
                />
              </v-sheet>
              <v-sheet class="canceled-order-status-sheet">
                <v-subheader>PAYMENT</v-subheader>
                <StatusChip :status="canceledOrder.paymentStatus" />
              </v-sheet>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card flat outlined class="canceled-order-card price-card">
            <v-container>
              <v-row>
                <v-col>Subtotal:</v-col>
                <v-col>
                  {{
                    $parseFractionUnitToString(
                      canceledOrder.totalExclTax,
                      order.currency
                    )
                  }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>Tax:</v-col>
                <v-col>
                  {{
                    $parseFractionUnitToString(
                      canceledOrder.taxAmount,
                      order.currency
                    )
                  }}
                </v-col>
              </v-row>
              <v-row>
                <v-col>Fee:</v-col>
                <v-col>
                  &minus;
                  {{
                    $parseFractionUnitToString(
                      canceledOrder.feeAmount,
                      order.currency
                    )
                  }}
                </v-col>
              </v-row>
              <v-divider />
              <v-row>
                <v-col>Total Refunded:</v-col>
                <v-col>
                  <b>{{
                    $parseFractionUnitToString(
                      canceledOrder.totalInclTax -
                        (canceledOrder.feeAmount || 0),
                      order.currency
                    )
                  }}</b>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-card flat outlined class="canceled-order-items-card">
            <v-card-title>Order Items</v-card-title>
            <v-container>
              <v-data-table
                :headers="orderItemHeaders"
                :items="canceledOrder.items"
                hide-default-footer
                :items-per-page="-1"
                disable-sort
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td
                      class="text-start"
                      :data-test-id="'cancel_order_item_' + item.orderItemId"
                    >
                      {{
                        order.items.find(
                          (orderItem) => orderItem.id === item.orderItemId
                        ).number
                      }}
                    </td>
                    <td
                      class="text-start"
                      :data-test-id="
                        'cancel_order_item_' + item.orderItemId + '_sku'
                      "
                    >
                      {{
                        order.items.find(
                          (orderItem) => orderItem.id === item.orderItemId
                        ).sku
                      }}
                    </td>

                    <!-- eslint-disable-next-line -->
                    <td
                      v-for="(status, index) in [
                        'cancellationStatus',
                        'productionStatus',
                      ]"
                      :key="index"
                      class="text-start"
                    >
                      <StatusChip :status="item[status]" />
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import StatusChip from "../../common/display-helpers/StatusChip";
export default {
  props: {
    canceledOrder: {
      type: Object,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  components: {
    StatusChip,
  },

  computed: {
    orderItemHeaders() {
      return [
        { text: "Item Nr.", value: "number" },
        { text: "Product", value: "sku" },
        { text: "Cancellation Status", value: "cancellationStatus" },
        { text: "Production Status", value: "productionStatus" },
        { text: "", value: "" },
      ];
    },

    canceledItems() {
      return this.canceledOrder.items.filter(
        (ci) => ci.cancellationStatus === "COMPLETE"
      ).length;
    },
  },
};
</script>

<style scoped>
.canceled-order-card {
  height: 100%;
}

.canceled-order-card.price-card {
  text-align: left;
}

.canceled-order-card .v-divider {
  margin: 10px 0 10px 0;
}

.canceled-order-status-container {
  display: flex;
  justify-content: space-evenly;
}

.canceled-order-status-sheet {
  display: inline-flex;
  flex-flow: column;
  width: 160px;
  padding: 5px;
  align-items: center;
}

.canceled-order-status-sheet .v-chip,
.canceled-order-status-sheet .v-subheader {
  justify-content: center;
}

.canceled-order-price-card {
  text-align: left;
}

.canceled-order-price-card .v-divider {
  margin: 12px 0 12px 0;
}
</style>