var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"production-list-container"},[_c('v-data-table',{attrs:{"show-expand":"","hide-default-footer":"","headers":_vm.headers,"items":_vm.productions,"items-per-page":-1,"loading":_vm.runningAction,"expanded":_vm.expanded,"no-data-text":"No Productions found","data-test-id":"productionsTable"},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(isExpanded)?_c('v-btn',{attrs:{"icon":"","disabled":_vm.runningAction,"data-test-id":'production_' + item.id + '_hide_btn'},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"icon":"","disabled":_vm.runningAction,"data-test-id":'production_' + item.id + '_expand_btn'},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.status,"data-test-id":'production_' + item.id}})]}},{key:"item.items",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({},'td',attrs,false),on),[_c('ul',{staticClass:"production-item-list"},[_vm._l((_vm.getOrderItems(item).slice(0, 3)),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item.number)+" ")])}),(_vm.getOrderItems(item).length > 3)?_c('li',[_vm._v(" + "+_vm._s(_vm.getOrderItems(item).length - 3)+" more ")]):_vm._e()],2)])]}}],null,true)},[_c('ul',{staticClass:"production-item-list"},_vm._l((_vm.getOrderItems(item)),function(item,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(item.number)+" ")])}),0)])]}},{key:"item.nextRetryAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nextRetryAt ? _vm.$getLocalizedDate(item.nextRetryAt) : "-")+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.retryProductionEnabled(item))?_c('v-btn',{attrs:{"outlined":"","disabled":_vm.runningAction,"data-test-id":'retry_production_' + item.id + '_btn'},domProps:{"textContent":_vm._s('Retry producing')},on:{"click":function($event){return _vm.retryProducing(item)}}}):_vm._e()]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('ProductionDetail',{attrs:{"production":item,"order":_vm.order,"disabled":_vm.runningAction}})],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }