var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('MasterDetail',{attrs:{"data-test-id":"orderOverview","detail-open":!!_vm.selectedOrder},scopedSlots:_vm._u([{key:"toolbar",fn:function(){return [_c('Toolbar',{key:_vm.possibleFilters.length,staticClass:"order-table-toolbar",attrs:{"search-props":{
        possibleFilters: _vm.possibleFilters,
        disableFullTextSearch: true,
      }},on:{"update-filters":function (updated) { return (_vm.filters = updated); }},scopedSlots:_vm._u([{key:"extended",fn:function(){return [_c('div',{staticClass:"extended-toolbar"},[_c('v-select',{staticClass:"orders-limit-select",attrs:{"dense":"","hide-details":"","items":_vm.pageLimits,"disabled":_vm.loadingOrders,"data-test-id":"pageLimitSelector"},model:{value:(_vm.limit),callback:function ($$v) {_vm.limit=$$v},expression:"limit"}}),_vm._v(" rows "),_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}),(_vm.totalPages > 0)?_c('PaginationComponent',{attrs:{"totalPages":_vm.totalPages,"disabled":_vm.loadingOrders},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}}):_vm._e(),(_vm.totalPages > 0)?_c('v-divider',{staticClass:"mx-4",attrs:{"vertical":""}}):_vm._e(),(_vm.total)?_c('span',[_vm._v(_vm._s(_vm.total)+" total")]):_vm._e(),_c('v-spacer')],1)]},proxy:true}]),model:{value:(_vm.filterQuery),callback:function ($$v) {_vm.filterQuery=$$v},expression:"filterQuery"}})]},proxy:true},{key:"table",fn:function(){return [_c('v-data-table',{staticClass:"orders-table",attrs:{"options":_vm.options,"dense":"","disable-pagination":"","hide-default-footer":"","fixed-header":"","data-test-id":"orderTable","items":_vm.orders,"headers":_vm.headers,"loading":_vm.loadingOrders,"server-items-length":_vm.total,"single-select":true,"multi-sort":true,"height":_vm.tableHeight},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item",fn:function(ref){
      var item = ref.item;
return [_c('tr',{class:{
            selected: _vm.selectedOrder && item.id === _vm.selectedOrder.id,
          },attrs:{"data-test-id":'order_' + item.id},on:{"click":function($event){return _vm.showOrder(item.id)}}},[_c('td',{attrs:{"data-test-id":'order_number_' + item.id}},[_vm._v(" "+_vm._s(item.number)+" ")]),_c('td',{attrs:{"data-test-id":'order_createdAt_' + item.id}},[_vm._v(" "+_vm._s(_vm.$getLocalizedDate(item.createdAt))+" ")]),_c('td',{attrs:{"data-test-id":'order_shippingAddress_' + item.id}},[_vm._v(" "+_vm._s(item.shippingAddress ? item.shippingAddress.firstname + " " + item.shippingAddress.lastname : "")+" ")]),_c('td',{attrs:{"data-test-id":'order_billingAddress_' + item.id}},[_vm._v(" "+_vm._s(item.billingAddress ? item.billingAddress.firstname + " " + item.billingAddress.lastname : "")+" ")]),_c('v-menu',{staticClass:"order-item-menu",attrs:{"open-on-hover":"","right":"","offset-x":"","nudge-left":"30","close-delay":"100"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('td',_vm._g(_vm._b({},'td',attrs,false),on),[_c('ul',{staticClass:"order-item-list"},[_vm._l((item.items.slice(0, 3)),function(product,index){return _c('li',{key:index},[_vm._v(" "+_vm._s(product.sku)+" ")])}),(item.items.length > 3)?_c('li',[_vm._v(" + "+_vm._s(item.items.length - 3)+" more ")]):_vm._e()],2)])]}}],null,true)},[_c('ul',{staticClass:"order-item-list"},_vm._l((item.items),function(product,index){return _c('li',{key:index},[_c('a',{attrs:{"href":_vm.$router.resolve({
                      name: 'productDetail',
                      params: { product: product.sku },
                    }).href,"target":"_blank"}},[_vm._v(" "+_vm._s(product.sku)+" ")])])}),0)]),_vm._l(([
              'orderStatus',
              'paymentStatus',
              'productionStatus' ]),function(status,index){return _c('td',{key:index},[_c('StatusChip',{attrs:{"status":item[status]}})],1)}),_c('td',[_vm._v(" "+_vm._s(_vm.$parseFractionUnitToString(item.totalInclTax, item.currency))+" ")])],2)]}}])})]},proxy:true},{key:"detail",fn:function(){return [(_vm.selectedOrder)?_c('OrderDetail',{key:_vm.selectedOrder.id,style:({
        height: _vm.detailHeight + 'px',
      }),attrs:{"order":_vm.selectedOrder},on:{"reload":_vm.reloadPage,"close":_vm.closeOrderDetail}}):_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }