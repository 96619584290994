var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{attrs:{"items":_vm.statusHistory,"headers":_vm.headers,"items-per-page":-1,"expanded":_vm.expanded,"item-key":"timestamp","sort-by":"timestamp","hide-default-footer":"","show-expand":"","no-data-text":"No status history found","data-test-id":"paymentStatusHistoryList"},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(isExpanded && (item.message || item.pspResponse))?_c('v-btn',{attrs:{"icon":"","data-test-id":"payment_status_history_hide_btn"},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):(item.message || item.pspResponse)?_c('v-btn',{attrs:{"icon":"","data-test-id":"payment_status_history_expand_btn"},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1):_vm._e()]}},{key:"item.timestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getLocalizedDate(item.timestamp))+" ")]}},{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.code}})]}},{key:"item.message",fn:function(ref){
var item = ref.item;
return [(item.message)?_c('div',{staticClass:"status-history-message",attrs:{"title":item.message}},[_vm._v(" "+_vm._s(item.message)+" ")]):_c('v-subheader',[_vm._v("No message")])]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('v-container',[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-title',[_vm._v("Message")]),(item.message)?_c('v-card-text',{attrs:{"data-test-id":"payment_status_history_message"}},[_vm._v(" "+_vm._s(item.message)+" ")]):_vm._e()],1)],1),(item.pspResponse)?_c('v-container',[_c('GenericAttributesCard',{key:item.id,attrs:{"title":"PSP Response","icon":"mdi-format-list-bulleted-square","attributes":item.pspResponse,"data-test-id":"payment_status_history_psp_response"}})],1):_vm._e()],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }