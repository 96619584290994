var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"cancellation-list-container"},[_c('v-container',{staticClass:"cancellation-table-container"},[_c('v-data-table',{attrs:{"items":_vm.cancellations,"headers":_vm.headers,"sortBy":"createdAt","sort-desc":true,"item-key":"createdAt","hide-default-footer":"","items-per-page":-1,"show-expand":"","expanded":_vm.expanded,"no-data-text":"No Cancellations found","data-test-id":"cancellationTable"},scopedSlots:_vm._u([{key:"item.data-table-expand",fn:function(ref){
var item = ref.item;
var isExpanded = ref.isExpanded;
var expand = ref.expand;
return [(isExpanded)?_c('v-btn',{attrs:{"icon":"","data-test-id":'cancellation_hide_btn_' + item.id},on:{"click":function($event){return expand(false)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1):_c('v-btn',{attrs:{"icon":"","data-test-id":'cancellation_expand_btn_' + item.id},on:{"click":function($event){return expand(true)}}},[_c('v-icon',[_vm._v("mdi-chevron-right")])],1)]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$getLocalizedDate(item.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.items.filter(function (ci) { return ci.cancellationStatus == 'COMPLETE'; })
              .length == item.items.length
              ? 'COMPLETE'
              : 'FAILED',"text":item.items.filter(function (ci) { return ci.cancellationStatus == 'COMPLETE'; })
              .length +
            '/' +
            item.items.length}})]}},{key:"item.paymentStatus",fn:function(ref){
            var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.paymentStatus}})]}},{key:"item.feeAmount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseFractionUnitToString(item.feeAmount, item.currency))+" ")]}},{key:"item.totalInclTax",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseFractionUnitToString(item.totalInclTax, item.currency))+" ")]}},{key:"expanded-item",fn:function(ref){
            var item = ref.item;
return [_c('td',{attrs:{"colspan":_vm.headers.length + 1}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"flat":"","outlined":""}},[_c('v-card-title',[_vm._v("Cancellation Items")]),_c('v-data-table',{attrs:{"headers":_vm.itemHeaders,"items":item.items,"item-key":"orderItemId","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
            var item = ref.item;
return [_c('span',{attrs:{"data-test-id":'cancellation_item_' + item.orderItemId}},[_vm._v(" "+_vm._s(_vm.order.items.find( function (orderItem) { return orderItem.id === item.orderItemId; } ).number)+" ")])]}},{key:"item.product",fn:function(ref){
            var item = ref.item;
return [_c('span',{attrs:{"data-test-id":'cancellation_item_' + item.orderItemId + '_sku'}},[_vm._v(" "+_vm._s(_vm.order.items.find( function (orderItem) { return orderItem.id === item.orderItemId; } ).sku)+" ")])]}},{key:"item.productionStatus",fn:function(ref){
            var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.productionStatus}})]}},{key:"item.cancellationStatus",fn:function(ref){
            var item = ref.item;
return [_c('StatusChip',{attrs:{"status":item.cancellationStatus}})]}},{key:"item.feeAmount",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseFractionUnitToString( item.feeAmount, item.currency ))+" ")]}},{key:"item.rowTotalInclTax",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseFractionUnitToString( item.rowTotalInclTax, item.currency ))+" ")]}},{key:"item.priceInclTax",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$parseFractionUnitToString( item.priceInclTax, item.currency ))+" ")]}}],null,true)})],1)],1)],1)],1)],1)]}}])})],1),_c('v-container',{staticClass:"total-refund-container"},[_c('v-row',[_c('v-col',[_vm._v("Subtotal:")]),_c('v-col',[_vm._v(" "+_vm._s(_vm.$parseFractionUnitToString(_vm.subTotal, _vm.order.currency))+" ")])],1),_c('v-row',[_c('v-col',[_vm._v("Fees:")]),_c('v-col',[_vm._v(" − "+_vm._s(_vm.$parseFractionUnitToString(_vm.totalFees, _vm.order.currency))+" ")])],1),_c('v-divider'),_c('v-row',[_c('v-col',[_vm._v("Total Refunded:")]),_c('v-col',[_c('b',[_vm._v(_vm._s(_vm.$parseFractionUnitToString(_vm.totalRefunded, _vm.order.currency)))])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }