<template>
  <v-card outlined class="address-card-container">
    <v-card-title>
      <v-icon left>mdi-map-marker </v-icon>
      {{ title }}
    </v-card-title>
    <v-container>
      <v-list two-line>
        <v-list-item v-if="address.firstname || address.lastname">
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title data-test-id="addressFullName">
              {{ address.firstname + " " + address.lastname }}
            </v-list-item-title>
            <v-list-item-subtitle>Name</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="address.email">
          <v-list-item-icon><v-icon>mdi-email</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title data-test-id="addressEmail">
              {{ address.email }}
            </v-list-item-title>
            <v-list-item-subtitle>Email</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="address.phone">
          <v-list-item-icon><v-icon>mdi-phone</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title data-test-id="addressPhone">
              {{ address.phone }}
            </v-list-item-title>
            <v-list-item-subtitle>Phone</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="address.street">
          <v-list-item-icon><v-icon>mdi-home</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title data-test-id="addressStreet">
              {{ address.street }}
            </v-list-item-title>
            <v-list-item-subtitle>Street</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="cityAddress">
          <v-list-item-icon><v-icon>mdi-city</v-icon></v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title data-test-id="addressCityAddress">
              {{ cityAddress }}
            </v-list-item-title>
            <v-list-item-subtitle>City</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-container>
  </v-card>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: "Address",
    },

    address: {
      type: Object,
      required: true,
    },
  },

  computed: {
    cityAddress() {
      //create the full city address from city, postcode and country code
      let cityAddress = [];
      if (this.address.city) cityAddress.push(this.address.city);
      if (this.address.postcode) cityAddress.push(this.address.postcode);
      if (this.address.countryCode) cityAddress.push(this.address.countryCode);
      return cityAddress.length > 0 ? cityAddress.join(", ") : undefined;
    },
  },
};
</script>

<style scoped>
.address-card-container {
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
}
</style>