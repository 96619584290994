<template>
  <v-container class="cancellation-list-container">
    <v-container class="cancellation-table-container">
      <v-data-table
        :items="cancellations"
        :headers="headers"
        sortBy="createdAt"
        :sort-desc="true"
        item-key="createdAt"
        hide-default-footer
        :items-per-page="-1"
        show-expand
        :expanded="expanded"
        no-data-text="No Cancellations found"
        data-test-id="cancellationTable"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
          <v-btn
            icon
            v-if="isExpanded"
            @click="expand(false)"
            :data-test-id="'cancellation_hide_btn_' + item.id"
          >
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
          <v-btn
            icon
            v-else
            @click="expand(true)"
            :data-test-id="'cancellation_expand_btn_' + item.id"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.createdAt="{ item }">
          {{ $getLocalizedDate(item.createdAt) }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.status="{ item }">
          <StatusChip
            :status="
              item.items.filter((ci) => ci.cancellationStatus == 'COMPLETE')
                .length == item.items.length
                ? 'COMPLETE'
                : 'FAILED'
            "
            :text="
              item.items.filter((ci) => ci.cancellationStatus == 'COMPLETE')
                .length +
              '/' +
              item.items.length
            "
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.paymentStatus="{ item }">
          <StatusChip :status="item.paymentStatus" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.feeAmount="{ item }">
          {{ $parseFractionUnitToString(item.feeAmount, item.currency) }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.totalInclTax="{ item }">
          {{ $parseFractionUnitToString(item.totalInclTax, item.currency) }}
        </template>

        <template v-slot:expanded-item="{ item }">
          <td :colspan="headers.length + 1">
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-card flat outlined>
                    <v-card-title>Cancellation Items</v-card-title>
                    <v-data-table
                      :headers="itemHeaders"
                      :items="item.items"
                      item-key="orderItemId"
                      hide-default-footer
                    >
                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.number="{ item }">
                        <!-- Display number of original order item -->
                        <span
                          :data-test-id="
                            'cancellation_item_' + item.orderItemId
                          "
                        >
                          {{
                            order.items.find(
                              (orderItem) => orderItem.id === item.orderItemId
                            ).number
                          }}
                        </span>
                      </template>

                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.product="{ item }">
                        <!-- Display SKU of original order item -->
                        <span
                          :data-test-id="
                            'cancellation_item_' + item.orderItemId + '_sku'
                          "
                        >
                          {{
                            order.items.find(
                              (orderItem) => orderItem.id === item.orderItemId
                            ).sku
                          }}
                        </span>
                      </template>

                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.productionStatus="{ item }">
                        <StatusChip :status="item.productionStatus" />
                      </template>

                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.cancellationStatus="{ item }">
                        <StatusChip :status="item.cancellationStatus" />
                      </template>

                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.feeAmount="{ item }">
                        {{
                          $parseFractionUnitToString(
                            item.feeAmount,
                            item.currency
                          )
                        }}
                      </template>

                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.rowTotalInclTax="{ item }">
                        {{
                          $parseFractionUnitToString(
                            item.rowTotalInclTax,
                            item.currency
                          )
                        }}
                      </template>

                      <!-- eslint-disable-next-line -->
                      <template v-slot:item.priceInclTax="{ item }">
                        {{
                          $parseFractionUnitToString(
                            item.priceInclTax,
                            item.currency
                          )
                        }}
                      </template>
                    </v-data-table>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <v-container class="total-refund-container">
      <v-row>
        <v-col>Subtotal:</v-col>
        <v-col>
          {{ $parseFractionUnitToString(subTotal, order.currency) }}
        </v-col>
      </v-row>
      <v-row>
        <v-col>Fees:</v-col>
        <v-col>
          &minus; {{ $parseFractionUnitToString(totalFees, order.currency) }}
        </v-col>
      </v-row>
      <v-divider />
      <v-row>
        <v-col>Total Refunded:</v-col>
        <v-col>
          <b>{{ $parseFractionUnitToString(totalRefunded, order.currency) }}</b>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import StatusChip from "../../common/display-helpers/StatusChip";

export default {
  props: {
    cancellations: {
      type: Array,
      required: true,
    },

    order: {
      type: Object,
      required: true,
    },
  },

  components: {
    StatusChip,
  },

  data() {
    return {
      expanded: [],
    };
  },

  computed: {
    headers() {
      return [
        { text: "Created At", value: "createdAt" },
        { text: "Created By", value: "createdBy" },
        { text: "Status", value: "status" },
        { text: "Payment Status", value: "paymentStatus" },
        { text: "Fee", value: "feeAmount" },
        { text: "Total", value: "totalInclTax" },
      ];
    },

    itemHeaders() {
      return [
        { text: "Item Nr.", value: "number" },
        { text: "Product", value: "product" },
        { text: "Cancellation Status", value: "cancellationStatus" },
        { text: "Production Status", value: "productionStatus" },
        { text: "Price", value: "priceInclTax" },
        { text: "Quantity", value: "qty" },
        { text: "Fee", value: "feeAmount" },
        { text: "Total", value: "rowTotalInclTax" },
      ];
    },

    subTotal() {
      return this.cancellations
        .map((cancellation) => cancellation.totalInclTax)
        .reduce((a, b) => a + b, 0);
    },

    totalFees() {
      return this.cancellations
        .map((cancellation) => cancellation.feeAmount)
        .reduce((a, b) => (a || 0) + (b || 0), 0);
    },

    totalRefunded() {
      return this.subTotal - this.totalFees;
    },
  },
};
</script>

<style scoped>
.cancellation-table-container,
.total-refund-container {
  border: 1px solid lightgray;
  border-radius: 5px;
}

.cancellation-table-container {
  padding: 0;
}

.total-refund-container {
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  margin-top: 12px;
  width: 40%;
  float: right;
}

.total-refund-container .v-divider {
  margin: 12px 0;
}

.cancellation-list-container::v-deep .payment-list-container {
  border: none;
}

.cancellation-table-container
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded__row {
  background-color: var(--v-psblue-base);
  color: white;
}

.cancellation-table-container
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded__row:hover {
  color: black;
}
</style>
