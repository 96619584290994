<template>
  <v-container class="payment-list-container">
    <v-data-table
      :items="transactions"
      :headers="headers"
      show-expand
      :expanded="expanded"
      item-key="id"
      hide-default-footer
      :items-per-page="-1"
      no-data-text="No Payments found"
      data-test-id="paymentTable"
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          icon
          v-if="isExpanded"
          :loading="loading[item.id]"
          @click="expand(false)"
          :data-test-id="'payment_' + item.id + '_hide_btn'"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          icon
          v-else
          :loading="loading[item.id]"
          :disabled="loading[item.id]"
          @click="loadStatusHistory(item.id, expand)"
          :data-test-id="'payment_' + item.id + '_expand_btn'"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.createdTimestamp="{ item }">
        {{ $getLocalizedDate(item.createdTimestamp) }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.statusCode="{ item }">
        <StatusChip
          :status="item.statusCode"
          :data-test-id="'payment_' + item.id + '_status'"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:item.amount="{ item }">
        {{
          $parseFractionUnitToString(item.amount.value, item.amount.currency)
        }}
      </template>

      <!-- eslint-disable-next-line -->
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <v-container :data-test-id="'payment_' + item.id + '_detail'">
            <v-row>
              <v-col>
                <v-card flat outlined>
                  <v-card-title>
                    <v-icon left>mdi-credit-card-outline</v-icon>
                    Payment Information
                  </v-card-title>
                  <v-container>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            :data-test-id="'payment_' + item.id + '_type'"
                          >
                            {{ item.type }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Type</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            :data-test-id="
                              'payment_' + item.id + '_transaction_id'
                            "
                          >
                            {{ item.id }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Transaction ID</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            :data-test-id="
                              'payment_' + item.id + '_reference_number'
                            "
                          >
                            {{ item.referenceNumber }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Reference Number</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            :data-test-id="
                              'payment_' + item.id + '_merchant_id'
                            "
                          >
                            {{ item.merchantId }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Merchant ID</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            :data-test-id="'payment_' + item.id + '_provider'"
                          >
                            {{ item.provider }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Provider</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            :data-test-id="'payment_' + item.id + '_method_key'"
                          >
                            {{ item.paymentMethodKey }}
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Payment method</v-list-item-subtitle
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title
                            :data-test-id="'payment_' + item.id + '_amount'"
                          >
                            {{
                              $parseFractionUnitToString(
                                item.amount.value,
                                item.amount.currency
                              )
                            }}
                          </v-list-item-title>
                          <v-list-item-subtitle>Amount</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                  </v-container>
                </v-card>
              </v-col>
              <v-col
                v-if="
                  item.pspParameters &&
                  Object.keys(item.pspParameters).length > 0
                "
              >
                <GenericAttributesCard
                  :key="item.id"
                  title="PSP Parameters"
                  icon="mdi-format-list-bulleted-square"
                  :attributes="item.pspParameters"
                  :data-test-id="'payment_' + item.id + '_psp_parameters'"
                />
              </v-col>
            </v-row>
            <v-row>
              <!-- STATUS HISTORY -->
              <v-container>
                <v-card flat outlined class="payment-status-history-container">
                  <v-card-title>
                    <v-icon left>mdi-history</v-icon>
                    Status History
                  </v-card-title>
                  <v-container>
                    <v-row>
                      <v-col cols="2">Current Status:</v-col>
                      <v-col>
                        <StatusChip
                          :status="item.statusCode"
                          :data-test-id="'payment_' + item.id + '_status'"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="2">Last change:</v-col>
                      <v-col>{{
                        $getLocalizedDate(item.statusTimestamp)
                      }}</v-col>
                    </v-row>
                    <v-row>
                      <PaymentStatusHistory
                        :statusHistory="statusHistory[item.id]"
                        :data-test-id="'payment_' + item.id + '_status_history'"
                      />
                    </v-row>
                  </v-container>
                </v-card>
              </v-container>
            </v-row>
          </v-container>
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import StatusChip from "../../common/display-helpers/StatusChip";
import GenericAttributesCard from "../../common/display-helpers/GenericAttributesCard";
import PaymentStatusHistory from "./PaymentStatusHistoryList";

export default {
  props: {
    transactions: {
      type: Array,
      required: true,
    },
  },

  components: {
    StatusChip,
    GenericAttributesCard,
    PaymentStatusHistory,
  },

  data() {
    return {
      expanded: [],
      statusHistory: {},
      loading: {},
    };
  },

  methods: {
    async loadStatusHistory(id, expand /* method */) {
      this.$set(this.loading, id, true);
      try {
        //load history when expanding object
        let history = await this.$store.dispatch("get", {
          path: "/payment/" + id + "/statusHistory",
        });
        if (history) this.$set(this.statusHistory, id, history.statusHistory);
      } finally {
        this.$delete(this.loading, id);
        expand(true);
      }
    },
  },

  computed: {
    headers() {
      return [
        { text: "Type", value: "type" },
        { text: "Payment method", value: "paymentMethodKey" },
        { text: "Transaction ID", value: "id" },
        { text: "Created", value: "createdTimestamp" },
        { text: "Status", value: "statusCode" },
        { text: "Amount", value: "amount" },
      ];
    },
  },
};
</script>

<style scoped>
.payment-list-container {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0;
}

.payment-list-container .v-card {
  height: 100%;
}

.payment-list-container
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded__row {
  background-color: var(--v-psblue-base);
  color: white;
}

.payment-list-container
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded__row:hover {
  color: black;
}
</style>