<template>
  <v-container class="production-list-container">
    <v-data-table
      show-expand
      hide-default-footer
      :headers="headers"
      :items="productions"
      :items-per-page="-1"
      :loading="runningAction"
      :expanded="expanded"
      no-data-text="No Productions found"
      data-test-id="productionsTable"
    >
      <!-- eslint-disable-next-line -->
      <template #item.data-table-expand="{ item, isExpanded, expand }">
        <v-btn
          v-if="isExpanded"
          icon
          :disabled="runningAction"
          :data-test-id="'production_' + item.id + '_hide_btn'"
          @click="expand(false)"
        >
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
        <v-btn
          v-else
          icon
          :disabled="runningAction"
          :data-test-id="'production_' + item.id + '_expand_btn'"
          @click="expand(true)"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.status="{ item }">
        <StatusChip
          :status="item.status"
          :data-test-id="'production_' + item.id"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.items="{ item }">
        <v-tooltip bottom>
          <ul class="production-item-list">
            <li v-for="(item, index) in getOrderItems(item)" :key="index">
              {{ item.number }}
            </li>
          </ul>

          <template #activator="{ on, attrs }">
            <td v-on="on" v-bind="attrs">
              <ul class="production-item-list">
                <li
                  v-for="(item, index) in getOrderItems(item).slice(0, 3)"
                  :key="index"
                >
                  {{ item.number }}
                </li>
                <li v-if="getOrderItems(item).length > 3">
                  &plus; {{ getOrderItems(item).length - 3 }} more
                </li>
              </ul>
            </td>
          </template>
        </v-tooltip>
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.nextRetryAt="{ item }">
        {{ item.nextRetryAt ? $getLocalizedDate(item.nextRetryAt) : "-" }}
      </template>

      <!-- eslint-disable-next-line -->
      <template #item.actions="{ item }">
        <v-btn
          v-if="retryProductionEnabled(item)"
          v-text="'Retry producing'"
          outlined
          :disabled="runningAction"
          :data-test-id="'retry_production_' + item.id + '_btn'"
          @click="retryProducing(item)"
        />
      </template>

      <!-- eslint-disable-next-line -->
      <template #expanded-item="{ item }">
        <td :colspan="headers.length + 1">
          <ProductionDetail
            :production="item"
            :order="order"
            :disabled="runningAction"
          />
        </td>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import StatusChip from "../../common/display-helpers/StatusChip";
import ProductionDetail from "./ProductionDetail";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  components: {
    StatusChip,
    ProductionDetail,
  },

  data() {
    return {
      productions: [],
      expanded: [],
      runningAction: false,
      domainProductionStrategyIsAutomatic: false,
    };
  },

  async mounted() {
    await this.init();
    if (this.productions.some(({ status }) => status === "NEW")) {
      this.loadDomainProductionStrategy();
    }
  },

  methods: {
    async init() {
      try {
        this.runningAction = true;
        await this.loadProductions();
      } finally {
        this.runningAction = false;
      }
    },

    async loadProductions() {
      const query = "limit=-1&orderId=" + this.order.id;
      const res = await this.$store.$coreApi.coreOrderApi.getProductions(
        this.selectedDomain,
        query
      );
      this.productions = res?.result ?? [];
    },

    async loadDomainProductionStrategy() {
      const res = await this.$store.$coreApi.coreConfigurationApi.getMergedData(
        this.selectedDomain,
        "Configuration",
        "core.connectorhub.common"
      );
      this.domainProductionStrategyIsAutomatic =
        res?.data?.isProductionStrategyAutomatic ?? false;
    },

    getOrderItems(production) {
      const orderItems = this.order.items;
      if (!orderItems) return [];
      return orderItems.filter(({ id }) =>
        production?.orderItemIds?.includes(id)
      );
    },

    async retryProducing({ id }) {
      try {
        this.runningAction = true;
        await this.$store.$coreApi.coreOrderApi.retryProducing(
          this.selectedDomain,
          this.order.id,
          [id],
          "Retry producing of " + id
        );
        await this.init();
      } finally {
        this.runningAction = false;
      }
    },

    retryProductionEnabled({
      status,
      isManualProduction = false,
      retries = 0,
    }) {
      return (
        status === "NEW" &&
        this.domainProductionStrategyIsAutomatic &&
        (isManualProduction || retries > 0)
      );
    },
  },

  computed: {
    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    headers() {
      return [
        { text: "ID", value: "id" },
        { text: "Status", value: "status" },
        { text: "Item Ids", value: "items" },
        { text: "Next retry at", value: "nextRetryAt" },
        { text: "Retries", value: "retries" },
        { text: "", value: "actions" },
      ];
    },
  },
};
</script>

<style scoped>
.production-list-container {
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0;
}

.production-item-list {
  list-style-type: none;
}

.production-list-container
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded__row {
  background-color: var(--v-psblue-base);
  color: white;
}

.production-list-container
  .v-data-table::v-deep
  > .v-data-table__wrapper
  > table
  > tbody
  > tr.v-data-table__expanded__row:hover {
  color: black;
}
</style>