<template>
  <DetailView
    class="order-detail"
    data-test-id="orderDetail"
    :title="title"
    :loading="loading"
    :tabs="tabs"
    @close="$emit('close', false)"
  >
    <template #subtitle>
      <v-chip v-if="isPS2Order" color="warning" label text-color="white">
        <v-icon left> mdi-alert </v-icon>
        Order from Peaksolution 2
      </v-chip>
    </template>

    <template #actions="{ loading }">
      <v-btn
        outlined
        target="_blank"
        data-test-id="orderVoucher"
        :title="
          !voucher.url ? 'This order has no voucher' : 'Click to open voucher'
        "
        :href="voucher.url"
        :disabled="!voucher.url || loading"
      >
        <v-icon left>mdi-wallet-giftcard</v-icon>
        Voucher
      </v-btn>
      <v-btn
        v-if="canSendConfirmationMail"
        outlined
        title="Send order confirmation mail"
        class="ml-2"
        :disabled="loading"
        @click="sendOrderConfirmationMail"
      >
        <v-icon left>mdi-email-fast</v-icon>
        Send Mail
      </v-btn>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.overview>
      <v-container fluid data-test-id="orderOverview">
        <v-card
          outlined
          class="order-status-card"
          data-test-id="orderStatusCard"
        >
          <v-card-title> STATUS </v-card-title>
          <v-container fluid class="order-status-container">
            <v-sheet
              v-for="(status, index) in [
                'orderStatus',
                'paymentStatus',
                'productionStatus',
              ]"
              :key="index"
              class="order-status-sheet"
            >
              <v-subheader>{{
                status.toUpperCase().replace("STATUS", "")
              }}</v-subheader>
              <StatusChip :status="order[status]" class="justify-center" />
            </v-sheet>
          </v-container>
        </v-card>
        <v-container fluid class="order-dashboard-container">
          <v-row>
            <v-col>
              <v-card
                outlined
                class="order-dashboard-card"
                data-test-id="orderCustomerCard"
              >
                <v-card-title>
                  <v-icon left>mdi-account</v-icon>
                  Customer
                </v-card-title>
                <v-container>
                  <v-list two-line>
                    <v-list-item v-if="order.customerId">
                      <v-list-item-icon>
                        <v-icon>mdi-web</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title data-test-id="orderCustomerId">
                          <a
                            :href="
                              $router.resolve({
                                name: 'customerDetail',
                                params: { customer: order.customerId },
                              }).href
                            "
                            target="_blank"
                          >
                            {{ order.customerId }}
                          </a>
                        </v-list-item-title>
                        <v-list-item-subtitle>Customer-ID</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="customerName">
                      <v-list-item-icon>
                        <v-icon>mdi-account-circle</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ customerName }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Name</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="customer.email">
                      <v-list-item-icon>
                        <v-icon>mdi-email</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ customer.email }}
                        </v-list-item-title>
                        <v-list-item-subtitle>Email</v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-container>
              </v-card>
            </v-col>
            <v-col>
              <v-card
                outlined
                v-if="order.paymentType"
                class="order-payment-information-card order-dashboard-card"
              >
                <v-card-title>
                  <v-icon left>mdi-credit-card-outline</v-icon>
                  Payment Information
                </v-card-title>
                <v-list two-line>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ order.paymentType }}
                      </v-list-item-title>
                      <v-list-item-subtitle>Payment Type</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card>
              <v-card
                outlined
                class="order-dashboard-card"
                data-test-id="orderPriceCard"
              >
                <v-card-title>
                  <v-icon left>mdi-currency-usd</v-icon>
                  Price
                </v-card-title>
                <v-container class="order-price-container">
                  <v-row>
                    <v-col cols="3" class="font-weight-medium">
                      Subtotal:
                    </v-col>
                    <v-col>
                      <div class="order-price-amount">
                        {{
                          $parseFractionUnitToString(
                            order.totalExclTax,
                            order.currency
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="font-weight-medium"> Tax: </v-col>
                    <v-col>
                      <div class="order-price-amount">
                        {{
                          "+ " +
                          $parseFractionUnitToString(
                            order.taxAmount,
                            order.currency
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="font-weight-medium">
                      Total Discount:
                    </v-col>
                    <v-col>
                      <div class="order-price-amount">
                        {{
                          "- " +
                          $parseFractionUnitToString(
                            order.totalDiscount,
                            order.currency
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-divider />
                  <v-row>
                    <v-col cols="3" class="font-weight-medium"> Total: </v-col>
                    <v-col>
                      <div class="order-price-amount">
                        {{
                          $parseFractionUnitToString(
                            order.totalInclTax,
                            order.currency
                          )
                        }}
                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row
            v-if="
              (order.billingAddress &&
                Object.keys(order.billingAddress).length > 0) ||
              (order.shippingAddress &&
                Object.keys(order.shippingAddress).length > 0)
            "
          >
            <v-col v-if="order.billingAddress">
              <AddressCard
                title="Billing Address"
                :address="order.billingAddress"
                data-test-id="orderBillingAddressCard"
                class="order-dashboard-card"
              />
            </v-col>
            <v-col v-if="order.shippingAddress">
              <AddressCard
                title="Shipping Address"
                :address="order.shippingAddress"
                data-test-id="orderShippingAddressCard"
                class="order-dashboard-card"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-container class="pa-0">
          <v-row>
            <v-col>
              <v-card
                outlined
                class="order-dashboard-card"
                data-test-id="orderAttachmentCard"
              >
                <v-card-title>Wallets</v-card-title>
                <v-list data-test-id="orderWalletsList" class="pa-3">
                  <v-list-item-group v-if="wallets.length > 0">
                    <v-list-item
                      v-for="(wallet, index) in wallets"
                      :key="index"
                      :href="wallet.url"
                    >
                      <v-list-item-avatar>
                        <v-icon>mdi-wallet</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>{{
                          getWalletTitle(wallet)
                        }}</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                  <div
                    v-else
                    data-test-id="orderNoWalletsMsg"
                    class="d-flex flex-grow-1 justify-center"
                  >
                    <v-subheader> This order has no wallets </v-subheader>
                  </div>
                </v-list>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="Object.keys(order.attributes).length > 0">
            <v-col>
              <GenericAttributesCard
                :key="order.id"
                title="Order Attributes"
                icon="mdi-format-list-bulleted-square"
                :attributes="order.attributes"
                data-test-id="orderAttributesCard"
              />
            </v-col>
          </v-row>
          <v-row v-if="order.comment">
            <v-col>
              <v-card outlined class="order-dashboard-card">
                <v-card-title>Comment</v-card-title>
                <v-container fluid>{{ order.comment }}</v-container>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <OrderHistory v-if="!loading" :order="order" />
            </v-col>
          </v-row>
        </v-container>
      </v-container>
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.items>
      <OrderItemOverview
        :order="order"
        :cancellations="cancellations"
        @reload="
          $emit('reload');
          init();
        "
        data-test-id="orderItemList"
      />
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.payment>
      <PaymentOverview :transactions="transactions" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.productions>
      <ProductionOverview :order="order" />
    </template>

    <!-- eslint-disable-next-line -->
    <template #tab.cancellations>
      <CancellationsOverview :cancellations="cancellations" :order="order" />
    </template>
  </DetailView>
</template>

<script>
import DetailView from "../common/templates/DetailView";
import AddressCard from "./AddressCard";
import OrderItemOverview from "./item/OrderItemOverview";
import GenericAttributesCard from "../common/display-helpers/GenericAttributesCard";
import CancellationsOverview from "./cancellation/CancellationsOverview";
import ProductionOverview from "./production/ProductionOverview";
import StatusChip from "../common/display-helpers/StatusChip";
import PaymentOverview from "./payment/PaymentOverview";
import OrderHistory from "./OrderHistory";
export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },

  components: {
    DetailView,
    AddressCard,
    OrderItemOverview,
    GenericAttributesCard,
    CancellationsOverview,
    ProductionOverview,
    StatusChip,
    PaymentOverview,
    OrderHistory,
  },

  data() {
    return {
      selectedTab: 0,
      history: [],
      cancellations: [],
      transactions: [],
      loading: true,
    };
  },

  async mounted() {
    await this.init();
  },

  methods: {
    async init() {
      this.loading = true;
      try {
        await this.loadCancellations();
        await this.loadTransactions();
      } finally {
        this.loading = false;
      }
    },

    async loadCancellations() {
      const body = {
        orderId: this.order.id,
      };

      const res = await this.$store.$coreApi.coreOrderApi.getCancellations(
        this.selectedDomain,
        "exact=true",
        body
      );

      if (!res?.ok) return;
      const response = await res.json();
      this.cancellations = response.result;
    },

    async loadTransactions() {
      const res = await this.$store.$coreApi.coreOrderApi.getPayments(
        this.selectedDomain,
        this.order.id
      );
      this.transactions = res ?? [];
    },

    async sendOrderConfirmationMail() {
      const body = {
        attachments: this.order.attachments,
      };
      await this.$store.$coreApi.coreOrderApi.sendOrderConfirmationMail(
        this.selectedDomain,
        this.order.id,
        body,
        {
          successMsg:
            "Sent order confirmation mail for order " + this.order.number,
        }
      );
    },

    getWalletTitle(wallet) {
      let title = wallet.filename;
      const orderItemIds = wallet.orderItemIds;
      if (orderItemIds && Array.isArray(orderItemIds)) {
        //Show the number of the wallets order items in the title
        const text = orderItemIds
          .map((id) => {
            const item = this.order?.items?.find?.((item) => item.id === id);
            return item?.number;
          })
          .filter((id) => !!id)
          .join(", ");
        title += " (For order item(s): " + text + ")";
      }
      return title;
    },
  },

  computed: {
    tabs() {
      return [
        { text: "Overview", value: "overview" },
        { text: "Items", value: "items" },
        { text: "Payment", value: "payment" },
        { text: "Productions", value: "productions" },
        { text: "Cancellations", value: "cancellations" },
      ];
    },

    title() {
      return "Order " + this.order.number;
    },

    canSendConfirmationMail() {
      return this.order.orderStatus === "COMPLETE" && this.hasAttachments;
    },

    hasAttachments() {
      return this.order.attachments && this.order.attachments.length > 0;
    },

    wallets() {
      const attachments = this.order?.attachments ?? [];
      return attachments.filter(({ type }) => type === "Wallet");
    },

    voucher() {
      const attachments = this.order?.attachments ?? [];
      return attachments.find(({ type }) => type === "Voucher") ?? {};
    },

    selectedDomain() {
      return this.$store.state.selectedDomain;
    },

    customer() {
      return this.order?.customerInfo ?? {};
    },

    customerName() {
      let name = [];
      if (this.customer.firstname) {
        name.push(this.customer.firstname);
      }
      if (this.customer.lastname) {
        name.push(this.customer.lastname);
      }
      return name.join(" ");
    },

    isPS2Order() {
      const attributes = this.order.attributes;
      return (
        attributes?.ps2OrderItemId !== undefined ||
        attributes?.ps2OrderNumber !== undefined
      );
    },
  },
};
</script>

<style scoped>
.order-detail-overlay {
  height: calc(100% - 122px);
  margin: 0;
  padding: 0;
  overflow-y: hidden;
}

.order-detail .col {
  display: flex;
  flex-direction: column;
}

.order-status-card .v-card__title {
  min-width: fit-content;
}

.order-status-container {
  display: flex;
  padding: 0;
  justify-content: space-evenly;
}

.order-status-card {
  display: flex;
}

.order-status-sheet {
  display: inline-flex;
  flex-flow: column;
  width: 160px;
  padding: 5px;
}

.order-status-sheet .v-chip,
.order-status-sheet .v-subheader {
  justify-content: center;
}

.resend-btn-container {
  background-color: white;
}

.order-dashboard-container {
  padding: 24px 0;
}

.order-timeline-container {
  max-height: 300px;
  overflow-y: scroll;
}

.order-timeline-container.empty-history {
  display: flex;
  justify-content: center;
}

.order-price-amount {
  display: flex;
  align-items: center;
}

.order-price-container .col {
  display: flex;
}

.order-price-container .v-divider {
  margin: 12px 0 12px 0;
}

.order-payment-information-card {
  margin-bottom: 24px;
}

.order-dashboard-card {
  display: flex;
  flex-flow: column;
  flex: 1 1 100%;
}
</style>

<style>
.order-detail-overlay .v-overlay--active {
  position: relative;
  height: 100%;
  width: 100%;
}

.order-detail-overlay .v-overlay--active .v-overlay__scrim {
  opacity: 0 !important;
  background-color: white !important;
}
</style>